@import '../../../assets/styles/variables/colors';
@import '../../../assets/styles/variables/variables';

.innerMenu {
    border-bottom: 1px solid #ededed;
    display: flex;
    justify-content: space-between;

    .more-button-container {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        .more-button-wrapper {
            width: 80px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            margin-bottom: 14px
        }
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        li {
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;
            line-height: 48px;
            border-right: 1px solid #ededed;
            border-bottom: 1px solid #ededed;
            margin-bottom: -1px;

            >a {
                padding: 0 $spacing-3;
                font-weight: 400;
                white-space: nowrap;
                display: block;
                line-height: 48px;
                color: #212121;

                &:hover {
                    background-color: rgba(107, 114, 128, 0.05);
                }

                &.active {
                    background-color: #626368;
                    color: #fff;
                }

                &.disabled {
                    color: $N200;
                    pointer-events: none;
                    cursor: default;

                    &:hover {
                        background-color: unset;
                    }
                }
            }

            &:last-child {
                border-right: unset;
            }
        }
    }
}
@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/functions/functions';

.textBoxWrapper {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 1;
    height: calc(100% - 48px);
    border-radius: radius(3);

    .formulaBox {
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        width: 100%;
        height: 100%;
        resize: none;
        padding: space(6) space(4);

        &:-webkit-scrollbar {
            display: none;
        }

        &:focus {
            border: none;
            outline: none;
            box-shadow: none;
        }
    }

    .boxFooter {
        display: flex;
        flex-direction: column;

        .resultWrapper {
            display: flex;
            flex-direction: column;
            padding: $spacing-2 $spacing-3;
            border-top: 1px solid #E8E8E8;

            .title {
                color: #888;
                text-overflow: ellipsis;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
            }

            .value {
                color: #222;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: capitalize;
            }

            .inValidFormula {
                color: #F25325;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: capitalize;
            }
        }

        .tipsWrapper {
            display: flex;
            flex-direction: column;
            padding: $spacing-2 $spacing-3;
            border-top: 1px solid #E8E8E8;
            background: rgba(197, 197, 197, 0.10);

            .title {
                color: #888;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
            }

            .items {
                display: flex;
                flex-direction: row;
                margin-top: $spacing-1;

                .title {
                    color: #323232;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .suffix {
                    margin-left: $spacing-1;
                    color: #888;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
            }
        }
    }
}

.textBoxContainer {
    position: relative;
    height: 100%;
}

.targetItemsWrapper {
    background-color: #fff;
    position: absolute;
    right: 0;
    left: space(4);
    border-radius: $border-radius-2;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    padding: space(4);
    width: calc(100% - 32px);

    >span {
        color: $N700;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
    }

    >div {
        min-height: 150px;
        max-height: 300px;
        overflow-y: auto;
    }
}
@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/functions/functions';

.overlayPanelContainer {
    max-height: 200px;
    overflow-y: auto;
    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;

        .searchBar {
            position: relative;
            //margin-right: 8px;
            flex: 1;

            .iconSection{
                position: absolute;
                color: $N400;
                width: 35px;
                top: 11px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 15px;
            }

            input {
                line-height: 35px;
                border: 1px solid $divider-color;
                border-radius: $border-radius-2;
                padding: 0 12px 0 35px;
                width: 100%;

                &:focus {
                    border-color: $divider-color;
                    box-shadow: unset;
                }
            }
        }
    }

    .noItemWrapper{
        display: flex;
        justify-content: center;

        span{
            font-size: 14px;
            font-weight: 700;
        }
    }

    .body {
        height: 140px;
        overflow-y: auto;
        .itemRow {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            min-height: 30px;
            .option {
                margin-left: 10px;
                font-size: 14px;
            }
        }
    }
}

.itemNameDropdownName{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #495057;
    font-size: 14px;
}

.secondColTemplate {
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding: space(3) 0 space(3);

    .templateName {
        display: block;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        margin-bottom: space(2);
        color: #000;
        padding: 0;
    }

    .templateBody {
        font-weight: 400;
        line-height: 15.12px;
        font-size: 12px;

        span {
            display: inline-block;
            color: #212121;
            padding-left: 4px;
        }

        div {
            color: #888888;

            span {
                color: #212121;
            }
            &:first-child {
                margin-bottom: space(1);
            }
        }
    }
}
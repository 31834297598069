@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';

.tab {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid $primary-border-color;
    border-bottom: 1px solid $primary-border-color;
    cursor: pointer;

    span {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
    }

    
}

.activeTab {
    border-bottom: 3px solid $card-active-border-color;
    background-color: #eff2ff;
    cursor: pointer;

    span {
        color: $button-primary-color;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
    }
}

.nullItemWarningWrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100px;
    justify-content: space-between;

    .bodyText {
        color: #212121;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .actionWrapper{
        margin-top: $spacing-2;
        display: flex;
        justify-content: flex-end;
    }
}
@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';

.targetItemsCard {
    margin-top: $spacing-3;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    >span {
        margin-left: $spacing-2;
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
    }
}
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/functions/functions';

.card {
    background-color: $N0;
    border: 1px solid $divider-color;
    border-radius: $border-radius-2;
    padding: $spacing-4;
    margin-bottom: $spacing-2;
    box-shadow: 0 1px 3px rgba($N900, 0.1);
    transition: box-shadow 0.2s ease;

    &:hover {
        box-shadow: 0 3px 6px rgba($N900, 0.15);
    }
}

.cardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $spacing-3;
}

.cardTitle {
    margin: 0;
    font-size: $font-size-4;
    color: $N800;
}

.cardActions {
    display: flex;
    align-items: center;
}

.visibilityToggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-top: $spacing-4;

    .p-checkbox {
        margin-right: $spacing-2;
    }

    label {
        font-size: $font-size-2;
        color: $N700;
    }
}

.cardBody {
    p {
        margin: 0;
        font-size: $font-size-3;
        color: $N700;
    }
}
@import '../../../assets/styles/variables/colors';
@import '../../../assets/styles/variables/variables';
@import '../../../assets/styles/functions/functions';

.formLoading {
    opacity: 0.6;
    pointer-events: none;
}

.formRepeater {
    border: 1px solid #eee;
    border-radius: radius(2);
    padding: space(4) space(3) space(5);
    position: relative;
    > label {
        position: absolute;
        left: space(2);
        top: -15px;
        line-height: 30px;
        padding: 0 5px;
        background-color: #fff;
        font-weight: 600;
        font-size: 14px;
    }
    &AddButton {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #3861FB;
        border-radius: space(2);
        color: #fff;
        margin: 0 space(2);
        cursor: pointer;
    }
    &Item {
        position: relative;
        border: 1px solid #eee;
        margin-bottom: space(3);
        border-radius: radius(2);
        padding: space(2);
        > svg {
            position: absolute;
            cursor: pointer;
            right: -15px;
            border-radius: 50%;
            background-color: #ffcfcf;
            color: #bd2121;
            width: 30px;
            height: 30px;
            top: 10px;
        }
    }
}

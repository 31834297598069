@import 'src/assets/styles/functions/functions';
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/variables/colors';

.mediaWrapper {
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-left: space(3);

    &Title {
        line-height: 18px;
        font-size: 14px;
        font-weight: 600;
    }

    &Desc {
        margin-top: space(1);
        line-height: 18px;
        font-size: 12px;
        font-weight: 400;
    }
}

.tooltip {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
    background-color: #333;
    align-items: center;
    gap: space(2);
    padding: 0 space(3);
    color: #ccc;
    font-size: 12px;
    border-radius: radius(2);
    line-height: 24px;
    display: none;

    &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 100%;
        border-top: 4px solid #333;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        transform: translateX(-50%);
    }
    a {
        margin-left: space(1);
        padding: 0 space(1);
        color: #fff;
        font-weight: 500;
        text-decoration: underline;
    }
    &Active {
        display: flex;
    }
}
.numberOfUsed {
    position: relative;
    display: flex;
    align-items: center;
    gap: space(2);
    padding: 0 space(1);
    &Clickable {
        color: #3861fb;
        text-decoration: underline;
        cursor: pointer;
    }
}

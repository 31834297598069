@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/functions/functions';

.childCard {
    padding: space(3) space(4);
    border: 1px solid #E8E8E8;
    border-radius: radius(2);
    margin-top: space(2);
    cursor: pointer;
    &:hover {
        background: rgba(56, 97, 251, 0.08);
        border-color: $button-primary-color;
    }

    .rightSide {
        display: flex;
        flex-direction: column;

        .value {
            color: #222;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            text-transform: capitalize;

            small {
                font-size: 14px;
                font-weight: 700;
            }
        }

        .name {
            margin-top: space(3);
            overflow: hidden;
            color: #212121;
            text-overflow: ellipsis;
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            text-transform: capitalize;
        }
    }
}
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/functions/functions';

.accordionItem {
    display: flex;
    align-items: center;
    margin-top: space(3);
    &:first-of-type {
        margin-top: 0;
    }
    label {
        cursor: pointer;
    }
}
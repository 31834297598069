// Libs
@import "../../../node_modules/primereact/resources/primereact.min";
@import "../../../node_modules/primeicons/primeicons";
@import "../../../node_modules/primereact/datatable/datatable.min";
@import "../../../node_modules/primereact/dropdown/dropdown.min";
@import "../../../node_modules/primereact/multiselect/multiselect.min";
@import "../../../node_modules/primereact/radiobutton/radiobutton.min";
@import "../../../node_modules/primereact/checkbox/checkbox.min";
@import "../../../node_modules/primereact/multiselect/multiselect.min";
@import "../../../node_modules/primereact/calendar/calendar.min";
@import "../../../node_modules/primereact/inputnumber/inputnumber.min";
@import "../../../node_modules/primereact/accordion/accordion.min.css";

// base
@import "fonts";
@import "general";
@import "body";
@import "forms";
@import "prime-theme";
@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';

.stateWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    span {
        color: $N850;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        margin-right: $spacing-4;
    }
}

.dropdownItemRow{
    display: flex;
    flex-direction: row;
    align-items: center;
}
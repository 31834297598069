@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/functions/functions';


.customDropdown {
    border: unset;
    background-color: $N0;
    display: flex;
    align-items: center;
    height: 48px;
    border: 1px solid rgb(209, 213, 219);
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    cursor: pointer;
    flex-direction: row;
    overflow: hidden;
    width: 100%;

    .prefixWrapper {
        width: 48px;
        border-right: 1px solid rgb(209, 213, 219);
        background-color: #eaecef;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        span {
            color: #6d757d;
        }
    }

    .bodyWrapper {
        margin-left: $spacing-2;
    }

    &__disabled {
        @extend .customDropdown;
        opacity: 0.6;
        cursor: default;
    }
}


.sidePanelWrapper {
    display: flex;
    flex-direction: column;
    height: calc(100% - $header-height);
    overflow-y: auto;
    padding: 0px 0px;

    .tabsWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: $spacing-1;
        background: #F2F2F2;
        border-radius: $border-radius-3;


        .tab {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
            padding: $spacing-3;
            border-radius: $border-radius-3;
            margin: 0 $spacing-1;
            cursor: pointer;

            span {
                color: #212121;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
            }
        }

        .activeTab {
            background: #FFF;
            box-shadow: 0px 1px 12px 0px rgba(136, 136, 136, 0.15);
        }
    }
}

.sidePanelFullWrapper {
    height: 100%;

    &Header {
        display: flex;
        align-items: center;
        padding: space(4) space(8);
        background-color: #FCFCFC;

        span {
            color: #000;
            font-size: 20px;
            font-weight: 500;
        }

        &Buttons {
            margin-left: auto;
            display: flex;
            align-items: center;
        }
    }

    &Content {
        height: calc(100% - 72px);
        padding: space(6);
        display: flex;

        &Left {
            width: 30%;
            flex: 0 0 30%;
            border-radius: radius(3);
            border: 1px solid #E8E8E8;
            margin-right: space(4);
        }

        &Right {
            width: calc(70% - space(4));
            flex: 0 0 calc(70% - space(4));
            margin-left: auto;
            border-radius: radius(3);
            border: 1px solid #E8E8E8;
        }
    }

    &Tabs {
        display: flex;
        border-bottom: 1px solid #E8E8E8;

        >div {
            flex: 0 1 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            line-height: 45px;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            border-bottom: 3px solid transparent;
            margin-bottom: -1px;

            &:first-of-type {
                border-top-left-radius: radius(3);
            }

            &:last-of-type {
                border-top-right-radius: radius(3);
            }

            &.active {
                background: rgba(56, 97, 251, 0.08);
                border-color: $button-primary-color;
                color: $button-primary-color;
                font-weight: 600;
            }
        }
    }
}
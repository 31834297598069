@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/functions/functions';

.footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $N0;

    .draftWrapper {
        display: flex;
        align-items: center;
        border-top: 1px solid $primary-border-color;
        padding: space(3) space(7);
        > span {
            font-size: 14px;
            font-weight: 500;
        }
        > div {
            margin-left: space(3);
        }
    }

    .financialDateWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: space(7);
        border-top: 1px solid $primary-border-color;

        .scrollableArea {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-x: auto;
            flex: 1;
        }
    }

    .actionWrapper {
        height: 64px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: space(2) space(7);
        border-top: 1px solid $primary-border-color;

        .buttonsWrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            &Saved {
                margin-right: space(4);
                color: green;
                font-weight: 500;
                opacity: 0;
                visibility: hidden;
                transition: 0.15s;
                display: flex;
                align-items: center;
                gap: space(1);
                i {
                    color: rgb(110, 181, 110);
                }
                &.active {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    .toggleModal {
        font-weight: 500;
        margin-left: space(3);
        margin-right: auto;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
            color: $button-primary-color;
        }
    }
}

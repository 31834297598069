@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/functions/functions';

.shareHolder {
    width: 100%;
    min-height: 86vh;
    position: relative;
}

.header {
    padding: $spacing-4 $spacing-8;
    display: flex;
    align-items: center;
    background: #fcfcfc;
    position: relative;

    &Title {
        display: flex;
        flex-direction: column;

        > h3 {
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
        }

        > span {
            color: rgba(0, 0, 0, 0.6);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    &Suffix {
        position: sticky;
        margin-left: auto;
    }
}

.main {
    max-width: 972px;
    width: 100%;
    margin: 0 auto;
    padding: space(4) space(4.5) 0 space(8);
}
.transactions_row {
    max-width: 922px;
    width: 100%;
    margin: 0 auto;
    padding-top: space(4);
}

.addBtn {
    background: #fff;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: space(3) 0;
    span {
        cursor: pointer;
        padding: 0 space(3);
        line-height: 40px;
        display: flex;
        gap: space(2);
        color: #3861fb;
        font-size: 16px;
        font-weight: 500;
        transition: 0.2s;
        border-radius: space(2);

        &:hover {
            background-color: rgba(56, 98, 251, 0.05);
        }

        &.disabled {
            cursor: default;
            pointer-events: none;
            color: #999;
            &:hover {
                background-color: unset
            }
        }
    }
}

.errors {
    margin: 40px 0 20px;
    color: red;
    ul {
        padding-left: 40px;
        list-style: square;

        li {
            padding-top: 6px;
        }
    }
}

@import '../../../assets/styles/variables/colors';
@import '../../../assets/styles/variables/variables';

.wrapper {
    display: flex;
    flex-direction: column;
    border-radius: $border-radius-1;

    .header {
        display: flex;
        flex-direction: row;
        padding: $spacing-3 $spacing-9;
        background-color: #f8f9fa;
        border-radius: $border-radius-1;
        border-bottom: 1px solid #dee2e6;
        cursor: pointer;
        align-items: center;
        justify-content: space-between;
        opacity: 0.9;

        >span {
            color: #212121;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
        }

        .iconsWrapper {
            display: flex;
        }

        .icon {
            width: 40px;
            height: 40px;
            border-radius: $border-radius-2;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $N0;
            margin-left: $spacing-2;
        }

        &:hover {
            opacity: 1;
        }
    }

    .contentBody {
        width: 100%;
        background: #FFF;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        padding: 0px $spacing-5 0px $spacing-9;
        transition: 0.2s;
        height: 0px;
        border-radius: $border-radius-1;

        .contentWrapper {
            padding: $spacing-4 0px;
            display: flex;
            width: 100%;
        }
    }
}
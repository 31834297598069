@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/functions/functions';

.dashboardTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: space(4);
  height: 100%;
  background-color: #FCFCFC;

  .headerContent {
    text-align: justify;
    width: 500px;
    > h1 {
      font-weight: 500;
      text-transform: capitalize;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: space(2);

      small {
        font-weight: 400;
        margin-left: space(2);
        color: $N600;
      }
    }

    .description {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $N600;

      p {
        margin-bottom: space(2);
      }
    }
  }
}

@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';



.financialDataSidePanelBody {
    display: flex;
    flex-direction: column;
    padding-top: $spacing-4;
    position: relative;
    height: calc(100% - 30px);
    overflow-y: auto;

    .radioButtonsWrapper {
        display: flex;
        flex-direction: row;

        div {
            cursor: pointer;

            span {
                color: $N850;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
            }
        }
    }

    .actionWrapper {
        padding: $spacing-6 0px;
        display: flex;
        flex-direction: column;
    };

    .cardsWrapper{
        padding: $spacing-6 0px;
        display: flex;
        flex-direction: column;
        flex: 1;
        .title {
            color: $N850;
            // font-family: Outfit;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
        };

        .selectedDateWrapper{
            max-height: 300px;
            overflow-y: auto;
            .card{
                margin-top: $spacing-3;
                padding: $spacing-3;
                height: 40px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                border-radius: $border-radius-2;
                border: 1px solid $primary-border-color;
                box-shadow: 0px 0px 12px 0px rgba(136, 136, 136, 0.08);

                div{
                    cursor: pointer;
                }

                span{
                    color: $N900;
                    // font-family: Outfit;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: capitalize;
                }
            }

        }
    }

    .buttonWrapper{
        width: 100%;
    }
    
}
@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/functions/functions';
@import 'src/assets/styles/variables/variables';

.statusDropDown {
    display: flex;
    align-items: center;

    > span {
        color: #212121;
        font-size: 14px;
        font-weight: 500;
        margin-right: space(3);
    }
    &Panel {
        :global {
            .p-dropdown-items {
                .p-dropdown-item:last-of-type {
                    margin-top: 7px;
                    overflow: unset;
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        pointer-events: none;
                        top: -4px;
                        background-color: #E8E8E8;
                        height: 1px;
                    }
                }
            }
        }
    }

    &Wrapper {
        min-width: 185px;

        :global {
            .p-dropdown {
                padding: 0;
                min-width: 100%;
                width: 100%;

                &-label {
                    padding-right: 0;
                }

                &-trigger {
                    margin-left: space(5);
                }
            }
        }

        &.loading {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                z-index: 10;
                right: 9px;
                top: calc(50% - 11px);
                width: 18px;
                height: 18px;
                border: 2px solid;
                border-color: $button-primary-color transparent;
                border-radius: 50%;
                display: inline-block;
                animation: rotation 1s linear infinite;
            }
        }
    }

    &Item {
        display: flex;
        align-items: center;

        small {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: space(2);
        }

        span {
            color: #212121;
            font-size: 14px;
            font-weight: 500;
        }
    }
}

.dropDown {
    &Link {
        display: flex;
        align-items: center;
        line-height: 48px;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

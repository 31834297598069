@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/functions/functions';

.formFieldRadio2 {
    display: flex;
    align-items: center;

    &Title {
        display: flex;
        align-items: center;
        gap: 8px ;
        label {
            color: #333;
            font-weight: 600;
            margin-bottom: 8px;
            line-height: 18px;
            display: flex;
            font-size: 14px;
            align-items: center;

            p {
                color: #333;
                font-weight: 600;
                margin-bottom: 8px;
                line-height: 18px;
                display: flex;
                font-size: 14px;
                align-items: center;
            }

            span {
                color: $red;
                margin-left: 4px;
                font-size: 16px;
                display: inline-block;
                line-height: 15px;
            }

        }
        :global {
            .form-input-error {
                margin-top: space(2);
                display: flex;
                align-items: center;
                gap: space(1);
                padding: 0;
            }


        }
    }

    &Inputs {
        display: flex;
        align-items: center;
        margin-left: auto;
    }
}

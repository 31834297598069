@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/functions/functions';

.initial-mode-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    margin-top: $spacing-16;

    &__title {
        color: $N850;
        font-size: $font-size-5;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        text-transform: capitalize;
    }

    &__text {
        color: $N850;
        font-size: $font-size-2;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        text-transform: capitalize;
        margin-top: $spacing-2;
    }

    &__buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;

        &__button {
            margin-top: $spacing-3;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
}


.companyName {
    line-height: 18px;
    font-size: 14px;
    font-weight: 400;
}
.companyTag {
    line-height: 15px;
    font-size: 12px;
    font-weight: 400;
    color: #888;
    height: 16px;
    display: flex;
    justify-content: start;
    align-items: center;

    margin-top: space(2);

    .shortName {
        padding-bottom: space(1);
    }

    .tagWrapper {
        display: flex;
        align-items: center;
        gap: space(1);
        svg {
            width: 16px;
            height: 16px;
        }
    }
}
.companyList {
    &Actions {
        display: flex;
        align-items: center;
        gap: space(1.5);
        padding: 0 space(1);
        a {
            margin: 0;
        }
    }
    :global {
        .status-dropdown {
            display: inline-flex;

            &-wrapper {
                min-width: unset;
                width: 40px;
                height: 40px;
            }
            .p-dropdown {
                border-radius: radius(3);
                border-color: #d9d9d9;
            }
            .p-dropdown-trigger {
                display: none;
            }
            .p-inputtext {
                padding: 0;
                line-height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    color: $button-primary-color;
                }
            }
        }
    }
}

.bottom-holder {
    position: sticky;
    bottom : 0;
    left : -14px;
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(0,0,0,0.2);
    background-color: #fff;
    -webkit-box-shadow: 0px -1px 5px 1px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px -1px 5px 1px rgba(0,0,0,0.1);
    box-shadow: 0px -1px 5px 1px rgba(0,0,0,0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    animation: fade-in-from-bottom 0.8s ease-in-out;    
    font-size: 14px;

    span {
        color: $button-primary-color;
        margin-right: 8px;
    }
}

@keyframes fade-in-from-bottom {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.loading {
    width: 20px;
    height: 20px;
    border: 2px solid;
    margin-right: 8px;
    border-color: $button-primary-color $button-primary-color transparent;
    border-radius: 50%;
    animation: rotation 0.75s linear infinite;
}
@keyframes rotation {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}
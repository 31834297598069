@import '../../../../../assets/styles/functions/functions';

.tooltip {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
    background-color: #333;
    align-items: center;
    gap: space(2);
    padding: 0 space(3);
    color: #ccc;
    font-size: 12px;
    border-radius: radius(2);
    line-height: 24px;
    display: none;

    &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 100%;
        border-top: 4px solid #333;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        transform: translateX(-50%);
    }
    a {
        margin-left: space(1);
        padding: 0 space(1);
        color: #fff;
        font-weight: 500;
        text-decoration: underline;
    }
}
.numberOfUsed {
    position: relative;
    display: inline-block;
    &:hover {
        .tooltip {
            display: flex;
        }
    }
}

@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/functions/functions';

.wrapper {
    min-width: 410px;
    padding: space(2);

    .body {
        line-height: 20px;
        font-size: 13px;
    }

    .footer {
        margin-top: space(6);
        display: flex;
        align-items: center;

        button {
            flex: 0 1 100%;
        }
    }
}
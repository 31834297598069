// Extended Neutral
$N0: #ffffff;
$N25: #FCFCFC;
$N50: #f9f9f9;
$N60: #F8F8F8;
$N75: #E7E7E7;
$N100: #cccccc;
$N200: #B3B3B3;
$N300: #999999;
$N400: #808080;
$N500: #666666;
$N600: #4d4d4d;
$N700: #3E3F44;
$N800: #2D2D31;
$N850: #212121;
$N900: #000000;

// Extended Velvet
$V50: #FFF1FA;
$V100: #FACFE8;
$V200: #F49FD2;
$V300: #F187C6;
$V400: #EC57B0;
$V500: #DA198C;
$V600: #C4177E;
$V700: #B91577;
$V800: #991162;
$V900: #780E4D;

// Extended Blue
$B50: #EBF4F9;
$B100: #C3DDEC;
$B200: #9CC6DF;
$B300: #74B0D3;
$B400: #4C99C6;
$B500: #3C8DBC;
$B600: #3378A0;
$B700: #2A6384;
$B800: #214E67;
$B900: #18384B;

// Extended Red
$G50: #F5FBF8;
$G100: #EEF8F4;
$G200: #DCF2EA;
$G300: #A3E6CD;
$G400: #52BD94;
$G500: #429777;
$G600: #317159;

// Extended Red
$R50: #FDF4F4;
$R100: #F9DADA;
$R200: #F4B6B6;
$R300: #EE9191;
$R400: #CF3E3E;
$R500: #A73636;
$R600: #7D2828;

// Extended Yellow
$Y50: #FFFAF1;
$Y100: #FFEFD2;
$Y200: #FFDFA6;
$Y300: #FFD079;
$Y400: #FFB020;
$Y500: #996A13;
$Y600: #66460D;

// Other Color
$P50: #EDE2FF;
$P900: #7844BA;
$O50: #FFF0DC;
$O900: #FF650F;

$body-background-color: #FFF;
$divider-color: #e7e7e7;
$blue: #0068BA;
$red: #E30000;
$green: #397A3C;

// new color config
$button-primary-color: #3861FB;
$button-secondary-border-color: #D9D9D9;
$primary-border-color: #ededed;
$card-active-border-color: #3861FB;
$tab-primary-color: #3E3F44;
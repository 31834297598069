@import '../../assets/styles/variables/colors';

.auth-content {
    display: flex;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
    padding-top: 10vh;
    background-color: #fff;

    &__header {
        text-align: center;
        margin-bottom: 80px;

        a {
            margin-bottom: 12px;
            display: inline-block;
        }

        h1 {
            font-weight: 700;
            line-height: 32px;
            font-size: 26px;
            margin-bottom: 12px;
            text-transform: uppercase;
        }

        p {
            font-size: 20px;
            color: $N700;
            line-height: 24px;
            margin-bottom: 0;
        }
    }

    &__form {
        max-width: 500px;
        padding: 0 16px;
        width: 100%;

        .form {
            &-button {
                margin-top: 60px;
            }
        }
    }
}


@media (max-width: 576px) {
    .auth-content {
        padding-top: unset;
        justify-content: center;

        &__header {
            margin-bottom: 30px;

            h1 {
                font-size: 18px;
            }

            p {
                font-size: 15px;
            }
        }

        &__form {
            .form {
                &-button {
                    margin-top: 20px;
                }
            }
        }
    }
}
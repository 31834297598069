@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';

.financial-information__landing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;

    >svg {
        margin-bottom: $spacing-2;
    }

    >h3 {
        margin-bottom: $spacing-4;
        margin-top: 0;
        color: $N700;
        font-size: $font-size-5;
        font-style: normal;
        font-weight: 700;
        line-height: $line-height-3; 
        text-transform: capitalize;
    }

    >p {
        color: $N700;
        font-weight: 400;
        line-height: $line-height-2;
        margin-top: 0;
        margin-bottom: $spacing-4;
        color: $N700;
        font-size: $font-size-2;
        font-style: normal;
        font-weight: 400;
        line-height: $line-height-3;
        text-transform: capitalize;
    }

    &__buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}
@import 'src/assets/styles/functions/functions';

.userRequestList {
    &Badge {
        background-color: #ffb83c;
        padding: 0 space(1.5);
        border-radius: radius(1);
        font-weight: 600;
        color: #fff;
        line-height: 16px;
        display: inline-block;
    }
    &Email {
        line-height: 20px;
    }
}
@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/functions/functions';

.financialDataWrapper {
    border: 1px solid $primary-border-color;
    display: flex;
    flex-direction: column;
    border-radius: space(2);
    position: relative;
    margin: space(2) $spacing-6;

    .headerTabsWrapper {
        height: 40px;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        position: absolute;
        top: 0px;
    }

    .body {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        padding: $spacing-3 $spacing-4;
        height: calc(100vh - 330px); 
        padding-bottom: 250px;

        .initialBody {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .addFirstRowActionWrapper {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.mainComponentWrapper {
    height: 100%;
    &.blur {
        height: calc(100vh);
        filter: blur(1px);
        pointer-events: none;
    }
}

@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/functions/functions';

:global {
    .p-dialog .p-dialog-content {
        padding: 0 !important;
    }

    .form-field {
        margin: 0;
    }
}

.dialogWrapper {
    position: relative;
    overflow: hidden;
}

.wrapper {
    min-width: 664px;
    padding: space(2) space(2) 0 space(2);

    .body {
        padding: space(3) 0;
        line-height: 20px;
        font-size: 13px;
    }

    .footer {
        margin-top: space(6);
        display: flex;
        align-items: center;

        button {
            flex: 0 1 100%;
        }
    }
}

.uploader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: radius(2);
    padding: 0 space(3);
    background: rgba(197, 197, 197, 0.2);
    color: rgba(0, 0, 0, 1);

    &Title {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        padding: space(2) space(1);

        span {
            font-weight: 500;
            font-size: 16px;
            line-height: 20.16px;
        }
    }

    &Formats {
        display: flex;
        margin-top: space(2);
        span {
            font-size: 12px;
            font-weight: 300;
            line-height: 15.12px;

            &:first-child {
                margin-right: space(2);
            }
        }
    }
}

.searchWrapper {
    margin-top: space(4);
    display: flex;
    justify-content: space-between;
    height: 48px;
    align-items: center;

    &Inputs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &Dropdown {
        flex-shrink: 3;
        margin-right: space(6);
        margin-left: space(1);
    }

    &Apply {
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.filesWrapper {
    margin-top: space(4);
    height: 300px;
    overflow-y: scroll;
}

.fileRow {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: space(2);
    padding: 0 space(8);
    cursor: pointer;

    &Checkbox {
        margin-right: space(5);
    }

    &Inner {
        display: flex;
        width: 100%;
        padding-bottom: space(2);
        margin-left: space(2);

        &Bordered {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
    }

    &Icon {
        margin-right: space(3);
        background: rgba(245, 245, 245, 1);
        width: 68px;
        height: 68px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: radius(2);
    }

    &Image {
        margin-right: space(3);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: radius(2);

        img {
            object-fit: contain;
            border-radius: radius(2);
        }
    }

    &Title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;

        &Heading {
            color: rgba(33, 33, 33, 1);
            font-weight: 500;
            font-size: 14px;
            line-height: 17.64px;
        }

        &Desc {
            margin-top: space(2);
            font-size: 14px;
            line-height: 17.64px;
            color: rgba(136, 136, 136, 1);
        }
    }
}

.pagination {
    height: 64px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0 space(3);
    background: #fff;
    z-index: 300;
}

.uploadingWrapper {
    min-width: 664px;
    //display: flex;
    //justify-content: start;
    padding: space(4);

    &Box {
        border: 1px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        border-radius: radius(3);
        padding: space(1);
    }

    &Success {
        display: flex;
        justify-content: start;
        align-items: start;
        padding: space(1) 0 space(1) space(4);
        width: 100%;

        &Icon {
            width: 52px;
            height: 52px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: radius(3);
            background: rgba(245, 245, 245, 1);
            margin-top: space(2);
        }

        &ButtonWrapper {
            display: flex;
            justify-content: start;
            flex-direction: row-reverse;
            align-items: center;
            width: 100%;

            button {
                margin-right: 0;
            }
            :first-child {
                margin-left: 4px;
            }
        }
    }

    &Items {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        padding: space(3);
        width: 100%;

        &Errors {
            border: 2px solid rgba(220, 78, 78, 1);
        }

        &ErrorText {
            max-width: 400px;
            margin-top: space(2);
            font-weight: 500;
            font-size: 10px;
            color: rgba(220, 78, 78, 1);
            display: flex;

            svg {
                margin-right: space(1);
            }
        }

        &Name {
            font-weight: 500;
            font-size: 14px;
            color: rgba(33, 33, 33, 1);
        }

        &Size {
            font-weight: 500;
            font-size: 14px;
            color: rgba(136, 136, 136, 1);
            margin-top: space(1);
            margin-bottom: space(3);
        }

        &Progress {
            margin-top: space(2);
            width: 100%;
        }

        &Desc {
            position: relative;
            margin-top: space(4);
            width: 100%;
        }

        &ItemsHelp {
            position: absolute;
            bottom: -8px;
            color: rgba(50, 50, 50, 1);
            font-weight: 400;
            font-size: 14px;
        }
    }
}

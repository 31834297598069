@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';

.financial-information {
    display: flex;
    flex-direction: column;
    position: relative;
    height: calc(100vh - $header-height - 49px);
}
.back-button {
    > span {
        opacity: 0.7;
        cursor: pointer;
        padding: 9px;
        span{
            font-size: 17px;
            margin-left: 5px;
        }
        i {
            font-size: 15px;
        }
    }
    > span:hover{
        opacity: 1;
    }
}

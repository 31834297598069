@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';

.financial-information__select-template {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: auto;

    >h3 {
        color: $N700;
        font-size: 24px;
        font-weight: 700;
        line-height: 22px;
        margin-top: 0;
        margin-bottom: $spacing-2;
    }

    >p {
        color: $N700;
        font-weight: 400;
        line-height: 25px;
        margin-bottom: $spacing-4;
    }

    .buttonsWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: $spacing-4;
    }

}
@import '../../../assets/styles/variables/colors';
@import '../../../assets/styles/variables/variables';

.main-header {
    background-color: $N0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 $spacing-9;
    height: $header-height;
    padding: $spacing-4 $spacing-6;
    border-bottom: 1px solid $primary-border-color;

    .profile{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: $spacing-1;
        cursor: pointer;
        span{
            font-weight: 700;
        }
    } 

    &__breadcrumbs {
        margin-right: auto;
        display: flex;

        a {
            color: $N800;
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 13px;

            &:after {
                content: "";
                width: 1px;
                height: 24px;
                background-color: $N75;
                transform: rotate(8deg);
                font-size: 22px;
                margin: 0 $spacing-3;
            }

            &:last-child {
                color: $N500;
                font-size: 12 px;
                font-weight: 400;

                &:after {
                    display: none;
                }
            }

            &:hover {
                color: $N800;
            }
        }
    }

}

.overlayPanel{
    padding: 0px;
    .overlayBodyItem{
        cursor: pointer;
        span{
            margin-left: 10px;
        }
    }
}
@import '../../assets/styles/variables/colors';
@import '../../assets/styles/variables/variables';

.not-found {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100vh;

    span {
        color: $N200;
        font-size: 26px;
    }

    h1 {
        font-size: 150px;
        font-weight: 900;
        color: $V500;
        letter-spacing: 20px;
        margin-bottom: $spacing-10;
    }
    h3 {
        margin-bottom: $spacing-5;
        
        font-size: 38px;
        font-weight: 600;
    }
    h6 {
        margin-bottom: $spacing-10;
        font-weight: 300;
        color: $N400;
        font-size: 20px;
    }
}

@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/variables/colors';

.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $spacing-10 0;
    .formWrapper{
        display: flex;
        flex-direction: column;
        .title{
            font-size: $font-size-5;
            font-weight: 600;
            line-height: $line-height-5;
        }
        .accordionWrapper{
            width: '100%';
            border-radius: $border-radius-2;

            .title{
                font-size: $font-size-3;
                font-weight: 600;
            }
            .accordion{
                .accordionItem{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }
    }
}
@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';

.wrapper {
    display: flex;
    flex-direction: column;
    padding: $spacing-3;
    border-radius: 8px;
    border: 1px solid $primary-border-color;
    margin-top: $spacing-3;
    cursor: pointer;
    .cardBody {
        display: flex;
        flex-direction: column;
        .title {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
        }
        .description {
            color: #888;
            display: flex;
            flex-direction: column;
            margin-top: $spacing-2;
            >span {
                margin-bottom: $spacing-2;
            }
            .descTitle {
                color: #888;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
            };
            .descItems{
                color: #888;
                margin-left: $spacing-5;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
                list-style:circle;
            }
        }
    }
}
.activeWrapper {
    border: 2px solid $card-active-border-color;
}

.disabled{
    opacity: 0.6;
}

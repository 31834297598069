@import 'src/assets/styles/functions/functions';
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/variables/colors';

.wrapper {
    position: relative;
    border-radius: radius(2);
    border: 1px solid #E8E8E8;
    padding: space(10.5) space(6);
    background: #FFF;

    >span {
        position: absolute;
        top: space(4);
        right: space(6);
        color: rgba(0, 0, 0, 0.56);
        font-size: 14px;
        padding-left: space(6);
        user-select: none;
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            width: 16px;
            height: 16px;
            left: 0;
            top: calc(50% - 9px);
            border-radius: radius(1);
            border: 1px solid rgba(197, 197, 197, 0.40);
            background: transparent;
        }

        &:after {
            content: '';
            position: absolute;
            width: 8px;
            height: 5px;
            left: 4px;
            top: calc(50% - 5px);
            border-width: 0 0 2px 2px;
            border-color: #fff;
            border-style: solid;
            transform: rotate(-45deg)
        }

        &.active {
            &:before {
                background: $button-primary-color;
                border-color: $button-primary-color;
            }
        }
        &.readOnly {
            opacity: 0.6;
            cursor: default ;
        }
    }

    &Formula {
        display: flex;
        align-items: center;

        &Value {
            >span {
                color: #323232;
                font-size: 16px;
                font-weight: 600;
                position: relative;
                cursor: default;

                small.prefix {
                    margin-right: space(1);
                }
            }

            >small {
                color: $button-primary-color;
                font-weight: 500;
                margin-left: space(2);
                margin-right: space(2);
            }
        }

        &Fraction {
            display: flex;
            flex-direction: column;

            :global {
                .denominator {
                    border-top: 1px solid #E8E8E8;
                }

                .numerator,
                .denominator {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    padding: space(1) 0;
                    min-width: 120px;

                    .operation {
                        color: $button-primary-color;
                        padding: 0 space(1);

                        &:hover {
                            color: $button-primary-color;
                        }
                    }

                    span,
                    div:not(.popover) {
                        display: flex;
                        align-items: center;
                    }

                    span {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 30px;

                        &.error {
                            color: #F25325;
                        }

                        &.item {
                            position: relative;
                            cursor: default;
                            display: flex;

                            >small.prefix {
                                font-size: 12px;
                                font-weight: 700;
                            }
                            :global {
                                .popover {
                                    min-width: 326px;
                                    position: absolute;
                                    padding: space(2) space(3) space(4) space(11);
                                    bottom: calc(100% + 10px);
                                    left: 100%;
                                    transform: translateY(-0px) translateX(-50%);
                                    background-color: #fff;
                                    border-radius: radius(3);
                                    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.10);
                                    border-bottom: 4px solid #F25325;
                                    transition: 0.15s 0.1s;
                                    opacity: 0;
                                    visibility: hidden;
                    
                                    >svg {
                                        position: absolute;
                                        left: space(3);
                                        top: space(3);
                                    }
                        
                                    h4 {
                                        color: #F25325;
                                        font-size: 14px;
                                        font-weight: 700;
                                    }
                        
                                    p {
                                        color: #323232;
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 21px;
                                    }
                        
                                    ul {
                                        list-style: disc;
                                        padding-left: space(4);
                                        color: #323232;
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 21px;
                                    }
                                }
                            }

                            &:hover {
                                :global {
                                    .popover {
                                        visibility: visible;
                                        opacity: 1;
                                        transform: translateY(0) translateX(-50%);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &Percent {
            >small {
                color: $button-primary-color;
                font-weight: 500;
                margin-left: space(2);
                margin-right: space(1);
            }
        }
    }
}

@media (max-width: 576px) {
    .formulaWrapper {
        padding: unset;
        border: unset;
        padding: unset;
        background: unset;

        >span {
            display: none
        }

        &Formula {
            flex-direction: column;
            align-items: stretch;

            &Value {
                background: rgba(197, 197, 197, 0.2);
                display: flex;
                justify-content: space-between;
                padding: space(4);
                align-items: center;
                margin-bottom: space(1);
                border-radius: radius(2);

                >span {
                    flex: 0 0 calc(100% - 14px);

                    small:not(.prefix) {
                        visibility: visible;
                        opacity: 1;
                        position: static;
                        left: unset;
                        transform: unset;
                        bottom: unset;
                        background-color: unset;
                        line-height: 15px;
                        padding: unset;
                        box-shadow: unset;
                        border-radius: unset;
                        color: #888;
                        font-weight: 400;
                        display: block
                    }

                    &:hover {
                        color: #222
                    }
                }

                >small {
                    flex: 0 0 14px;
                    margin: 0;
                    font-size: 24px
                }
            }

            &Fraction {
                flex: 0 0 100%;
                max-width: 100%;
                padding: space(4);
                background-color: #fff;
                border-radius: radius(2);
                border: 1px solid #E8E8E8;

                :global {

                    .numerator,
                    .denominator {
                        flex-direction: column;
                        align-items: center;
                        padding: 0;

                        div {
                            flex-direction: column;
                        }

                        span {
                            &.statement {
                                flex-wrap: wrap;
                                justify-content: center;

                                >div:not(.item) {
                                    flex: 0 0 calc(50% + 5px);

                                }

                                .parentheses-start,
                                .parentheses-end {
                                    align-self: flex-start
                                }
                            }

                            &.item {
                                font-size: 16px;
                                color: #222;
                                flex-wrap: wrap;
                                justify-content: center;

                                >small.prefix {
                                    display: block;
                                    margin-right: space(1);
                                    font-weight: 400
                                }

                                >small.popover {
                                    width: 100%;
                                    flex: 0 0 100%;
                                    text-align: center;
                                    justify-content: center;
                                    visibility: visible;
                                    font-size: 12px;
                                    opacity: 1;
                                    position: static;
                                    left: unset;
                                    transform: unset;
                                    bottom: unset;
                                    background-color: unset;
                                    line-height: 15px;
                                    padding: unset;
                                    box-shadow: unset;
                                    white-space: nowrap;
                                    border-radius: unset;
                                    color: #888;
                                    order: 3
                                }

                                &:hover {
                                    color: #222
                                }
                            }
                        }
                    }

                    .denominator {
                        padding-top: space(3);
                        margin-top: space(3);
                    }
                }
            }
        }
    }
}
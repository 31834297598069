@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/functions/functions';

.accordionItem {
    display: flex;
    align-items: center;
    margin-top: space(3);
    &:first-of-type {
        margin-top: 0;
    }
    label {
        cursor: pointer;
    }
}

.roleInputContainer {
    display: flex;
    align-items: center;
    min-height: 80px;
    padding: $spacing-7;
    background-color: $N60;
    border-radius: $border-radius-2;
    margin-bottom: $spacing-3;
}

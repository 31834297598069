@import '../../../../assets/styles/variables/colors';
@import '../../../../assets/styles/variables/variables';
@import '../../../../assets/styles//functions/functions';

.editCompany {
    .formWrapper{
        background-color: $N0;
        padding-bottom: space(20);
    }
    &Header {
        display: flex;
        align-items: center;
        padding: space(4) space(8);
        background-color: #FFF;
        position: sticky;
        top: 0;
        z-index: 5;
    }

    &Title {
        display: flex;
        flex-direction: column;

        h3 {
            font-size: 20px;
            font-weight: 500;
            line-height: 25px;
            margin-bottom: space(1);
        }

        p {
            margin: 0;
            color: rgba(0, 0, 0, 0.60);
            font-size: 16px;
            line-height: 20px;
        }
    }

    &Buttons {
        margin-left: auto;
        display: flex;
    }

    &Tabs {
        margin-bottom: 47px;
        position: sticky;
        top: 81px;
        z-index: 100;
        ul {
            display: flex;
            justify-content: center;
            border-bottom: 1px solid #E8E8E8;
            background-color: #fff;
            li {
                margin-bottom: -1px;
                div {
                    line-height: 66px;
                    color: #323232;
                    font-size: 16px;
                    font-weight: 600;
                    padding: 0 60px;
                    cursor: pointer;
                    border-bottom: 3px solid transparent;

                    &.active {
                        color: $button-primary-color;
                        border-color: $button-primary-color;
                    }
                }
            }
        }
    }
}
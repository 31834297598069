@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';

.financialDateCardWrapper {
    height: 50px;
    min-width: 155px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-right: 1px solid $primary-border-color;
    padding: 0 $spacing-3;
    cursor: pointer;

    .text {
        margin: 0 $spacing-2;
        color: $N900;
        // font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }

    .statusIcon{
        color: $N850;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize; 
    }

    .removeIcon{
        color: $N900;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize; 
        cursor: pointer;
    }
  
}

.active{
    background-color: rgba(56, 97, 251, 0.08);
    
    .text {
        color: $button-primary-color;
    }

    .statusIcon{
        color: $button-primary-color;
    }

    .removeIcon{
        color: $button-primary-color;
    }
}

.financialDateCardWrapper:first-child{
    border-left: 1px solid $primary-border-color;;
}

.nullItemWarningWrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100px;
    justify-content: space-between;

    .bodyText {
        color: #212121;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .actionWrapper{
        margin-top: $spacing-2;
        display: flex;
        justify-content: flex-end;
    }
}

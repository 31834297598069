@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/variables/colors';

.deleteModal {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 150px;
    justify-content: space-between;

    .mainText {
        color: #212121;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        max-width: 700px;
    }

    .secondaryText{
        color: #212121;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        max-width: 700px;
    }

    .actionWrapper{
        margin-top: $spacing-2;
        display: flex;
        justify-content: flex-end;
    }
}
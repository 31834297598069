@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/functions/functions';


.selectedItemsBox {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    &Wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;

    }

    &SelectOperators {
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.10);
        border-radius: radius(2);
        background: #FFF;
        //padding: space(2);
        height: 56px;
        margin-bottom: space(4);
        padding: space(4) space(4) 0;

    }

    &Search {
        margin-bottom: space(6);
        padding: space(4) space(4) 0;

        input {
            width: 100%;
            line-height: 38px;
            background: rgba(238, 238, 238, 0.60);
            border: 1px solid rgba(197, 197, 197, 0.40);
            border-radius: radius(2);
            padding: 0 space(2);
            &:focus {
                outline: unset;
            }
        }
    }

    &Items {
        overflow-y: auto;
        flex-grow: 1;
        padding: space(4);
    }

    &TipsWrapper {
        position: sticky;  // You can also use 'position: fixed' if you want it always visible at the bottom of the viewport.
        bottom: 0;
        display: flex;
        flex-direction: column;
        padding: $spacing-2 $spacing-3;
        border-top: 1px solid #E8E8E8;
        background: rgba(197, 197, 197, 0.10);

        .title {
            color: #888;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
        }

        .items {
            display: flex;
            flex-direction: row;
            margin-top: $spacing-1;

            .title {
                color: #323232;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            .suffix {
                margin-left: $spacing-1;
                color: #888;
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }
        }
    }
}

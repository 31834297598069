@import '../../../assets/styles/variables/colors';
@import '../../../assets/styles/variables/variables';
@import '../../../assets/styles/functions/functions';

.dashboardSidebar {
    width: $sidebar-width;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    border-right: 1px solid rgba(197, 197, 197, 0.70);
    background-color: #fff;
    z-index: 510;
    padding: space(10) space(8);
    transition: 0.15s;
    $dashboardSidebar: &;

    &Logo {
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
            flex: 0 0 48px;
        }

        span {
            flex: 0 0 calc(100% - 48px - space(4));
            margin-left: space(4);
            color: #262728;
            font-size: 22px;
            font-weight: 500;
            line-height: normal;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    &Menu {
        overflow: auto;
        scrollbar-width: none;
        height: calc(100% - 120px);
        margin-top: space(3);

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &Toggle {
        position: absolute;
        right: space(8);
        bottom: space(10);
        cursor: pointer;
        transition: 0.15s;
    }

    &.minimize {
        width: $sidebar-minimized-width;

        #{$dashboardSidebar}Toggle {
            right: 50%;
            transform: translateX(50%);
        }

        #{$dashboardSidebar}Menu {
            overflow: unset;
            scrollbar-width: unset;
        }
    }
}
@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/functions/functions';

.commentShow {
    &Wrapper {
        max-width: 600px;
        width: 100%;
        margin: space(15) auto 0;
        h3 {
            margin-bottom: space(6);
            font-weight: 500;
            font-size: 20px;
        }
    }
    &Items {
        border: 1px solid #ddd;
        padding: space(3);
        border-radius: radius(2);
        margin-top: space(3);
        > label {
            color: rgba(34, 34, 34, 0.4);
            font-size: 16px;
            font-weight: 500;
            text-transform: capitalize;
            line-height: 30px;
        }
    }
    &InnerItem {
        border-top: 1px solid #ddd;
        padding: space(3) 0;
        &:first-of-type {
            border-top: unset;
        }
        &:last-of-type {
            padding-bottom: 0;
        }
    }
    &Item {
        border: 1px solid #ddd;
        padding: space(3);
        border-radius: radius(2);
        margin-top: space(3);

        &:first-of-type {
            margin-top: 0;
        }
        label {
            color: rgba(34, 34, 34, 0.4);
            font-size: 16px;
            font-weight: 500;
            text-transform: capitalize;
            line-height: 30px;
        }
        span,
        p {
            display: block;
            color: #212121;
            font-size: 16px;
            font-weight: 600;
            line-height: 30px;
            margin-bottom: 0;
            margin-top: space(4);
        }
        a {
            display: block;
            font-size: 16px;
            font-weight: 600;
            line-height: 30px;
            margin-top: space(4);
        }
    }
}

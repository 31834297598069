@import 'src/assets/styles/functions/functions';
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/variables/colors';

.companyName {
    line-height: 18px;
    font-size: 14px;
    font-weight: 400;
}
.companyTag {
    line-height: 15px;
    font-size: 12px;
    font-weight: 400;
    color: #888;
    height: 16px;
    display: flex;
    justify-content: start;
    align-items: center;

    margin-top: space(2);

    .shortName {
        padding-bottom: space(1);
    }

    .tagWrapper {
        display: flex;
        align-items: center;
        gap: space(1);
        svg {
            width: 16px;
            height: 16px;
        }
    }
}
.companyList {
    &Actions {
        display: flex;
        align-items: center;
        gap: space(1.5);
        padding: 0 space(1);
        a {
            margin: 0;
        }
    }
    :global {
        .status-dropdown {
            display: inline-flex;

            &-wrapper {
                min-width: unset;
                width: 40px;
                height: 40px;
            }
            .p-dropdown {
                border-radius: radius(3);
                border-color: #d9d9d9;
            }
            .p-dropdown-trigger {
                display: none;
            }
            .p-inputtext {
                padding: 0;
                line-height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    color: $button-primary-color;
                }
            }
        }
    }
}

p {
    margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0;
}

ul,
ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
}

.custom-drop-down {
    line-height: 35px;
    ul {
        li.p-highlight {
            background-color: #fff !important;
            color: #000;
        }
    }
}

.bullet-of-status {
    width: 8px;
    height: 8px;
    border-radius: 16px;
    margin-right: $spacing-2;
}

#sentry-feedback {
    --bottom: unset;
    --top: 12px;
    --right: 175px;
    --box-shadow: unset;
    --border: unset;
    --input-border: 1.5px solid rgba(41, 35, 47, 0.13);
    --cancel-border: 1.5px solid rgba(41, 35, 47, 0.13);
    --z-index: 500;
}

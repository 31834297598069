@import '../../../assets/styles/functions/functions';

.link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3861fb;
    gap: space(1);

    &:visited {
        color: #3861fb;
    }
}

.icon {
    display: flex;
    align-items: center;
    svg {
        font-size: 20px;
        padding: 0;
        fill: #3861fb;
    }
}

@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';

.initial-mode-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    margin-top: $spacing-14;

    &__title {
        color: $N850;
        font-size: $font-size-5;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        text-transform: capitalize;
    }

    &__text {
        color: $N850;
        font-size: $font-size-2;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        text-transform: capitalize;
        margin-top: $spacing-2;
    }

    &__buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;

        &__button {
            margin-top: $spacing-3;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
}
@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';


.avatarWrapper {
    border-radius: 24px;
    border: 1px solid #E8E8E8;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .avatarImage {
        object-fit: cover;
        border-radius: 24px;
    }

    .uploaderIconWrapper {
        position: absolute;
        bottom: -10px;
        left: -10px;
        width: 35px;
        height: 35px;
        border-radius: 100%;
        background-color: $N75;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            font-size: $font-size-4;
        }
    }
}
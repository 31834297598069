@import '../../../assets/styles/variables/colors';
@import '../../../assets/styles/variables/variables';
@import '../../../assets/styles/functions/functions';

.dashboardTitle {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: space(4) space(8);
    background-color: #FCFCFC;
    position: sticky;
    top: 0;
    z-index: 500;

    >h1 {
        margin-right: auto;
        font-weight: 500;
        text-transform: capitalize;
        font-size: 20px;
        line-height: 25px;

        small {
            font-weight: 400;
            margin-left: space(2);
            color: $N600;
        }
    }
}
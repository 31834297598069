@import 'src/assets/styles/functions/functions';

.confirmationCompanyForm {
    margin-top: space(-17.5);
    &Header {
        padding: 0 space(9);
        line-height: 60px;
        border-top: 1px solid #EDEDED;
        border-bottom: 1px solid #EDEDED;
        background: #FFF;
        margin-bottom: space(9);
        color: #000;
        font-size: 16px;
        &:first-of-type {
            border-top: unset;
        }
    }
}
@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/functions/functions';


.rowContainer {
    width: 100%;
    padding: $spacing-2;
    border-radius: $border-radius-2;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    flex-shrink: 0;
    margin: 0 0;
    height: 75px;
    overflow: hidden;
    align-items: center;

    .selectBoxWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;

        .rowWrapper {
            min-width: 33%;
            max-width: 33%;
            padding: 0 $spacing-1;
            display: flex;
            align-items: center;
            flex-direction: column;

            .errorMessage {
                font-size: 11px;
                margin-bottom: -15px;
                color: $red;
                text-align: left;
                width: 100%;
            }
        }
    }

    .actionsWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        height: 48px;

        .action {
            border: 1px solid #e7e7e7;
            margin: 0 $spacing-2;
            width: 48px;
            height: 100%;
            border-radius: $border-radius-2;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $N0;
            color: $button-primary-color;
            cursor: pointer;

            i {
                font-size: 22px;
            }

            &__disabled {
                @extend .action;
                opacity: 0.5;
                cursor: default;
            }
        }

        .addNew {
            background-color: rgba(56, 97, 251, 0.08);
            border: none;
        }
    }
}

.itemTemplateBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;

    span {
        margin: $spacing-1;
        font-size: $font-size-2;
    }

    .value {
        font-weight: 700;
    }
}

.overlayBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    span {
        margin: $spacing-2 0;
        color: #222;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

:global {
    ::marker {
        padding : 0;
    }
}
.nullItemWarningWrapper {
    display: flex;
    flex-direction: column;
    width: 411px;

    .bodyText {
        color: #212121;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .bodyTextTitle {
        color: #212121;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
    }

    .list {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: rgba(33, 33, 33, 1);
        margin-top: space(2);
        list-style: inside;

        li {
            padding : 0;
        }
    }

    .actionWrapper{
        margin-top: space(2);
        display: flex;
        justify-content: flex-end;
    }
}

.customModalActionWrapper{
    width: 100%;
    margin-top: space(2);
    display: flex;
    justify-content: center;
    .customModalAction{
        flex: none;
        display: block;
        width: 150px;
    }
}

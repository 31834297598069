@import 'src/assets/styles/variables/colors';

.formFieldSearch {
    display: flex;
    gap: 0.5rem;

    &.loading {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            z-index: 10;
            right: 10px;
            top: calc(50% - 11px);
            width: 18px;
            height: 18px;
            border: 2px solid;
            border-color: $button-primary-color transparent;
            border-radius: 50%;
            display: inline-block;
            animation: rotation 1s linear infinite;
        }
    }
}


@keyframes rotation {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}
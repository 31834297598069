@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/functions/functions';

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100vh - $header-height - 49px);
    .financialDataType {
        display: flex;
        margin: space(4) 0 0;
        align-items: center;
        padding: 0 space(4);
    }
    .financialDataWrapper {
        border: 1px solid $primary-border-color;
        border-radius: radius(2);
        position: relative;
        margin: space(4);
        overflow: hidden;
        max-width: 100%;

        .headerTabsWrapper {
            height: 40px;
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            background-color: $N0;

            .tab {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                border-right: 1px solid $primary-border-color;
                border-bottom: 1px solid $primary-border-color;
                cursor: pointer;

                &:last-child {
                    border-right: unset;
                }

                span {
                    color: #000;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                }
            }

            .activeTab {
                border-bottom: 3px solid $card-active-border-color;
                background-color: #eff2ff;
                cursor: pointer;

                span {
                    color: $button-primary-color;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-transform: capitalize;
                }
            }
        }

        .body {
            display: flex;
            flex-direction: column;
            margin-top: 40px;
            overflow-y: auto;
            padding: $spacing-3 $spacing-4;
            height: 100%;
            padding-bottom: 150px;

            .initialBody {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .addFirstRowActionWrapper {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    .footerWrapper {
        position: absolute;
        bottom: 0px;
        width: 100%;
        border-top: 1px solid $primary-border-color;
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: $spacing-3 $spacing-4;
    }
    .footerDraftWrapper {
        position: absolute;
        bottom: 0px;
        width: 100%;
        border-top: 1px solid $primary-border-color;
        height: 60px;
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: $spacing-3 $spacing-4;
        > span {
            font-weight: 500;
            margin-right: space(2);
        }
    }
}

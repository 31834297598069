@import './variables/colors';
@import './functions/functions';

.form {
    &-input {
        width: 100%;
        border-width: 1px;
        border-style: solid;
        border-color: $N100;
        background-color: unset;
        padding: 0 10px;
        margin: 0;
        line-height: 59px;
        color: $N900;
        font-size: 13px;
        font-weight: 600;
        border-radius: $border-radius-2;

        &:focus,
        &:active {
            box-shadow: unset;
            outline: unset;
            border-color: $V500;
        }

        &--has-error {
            border-color: $R400;
        }
    }

    &-button-inline {
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        height: 100%;
    }

    &-editor {
        .ck-content {
            height: 220px;
        }
    }

    &-error-message {
        line-height: 36px;
        padding: 0 12px;
        background-color: $R50;
        color: $R500;
        font-size: 14px;

        span {
            display: flex;
            align-items: center;

            i {
                margin-right: 5px;
            }
        }
    }

    &-input-error {
        margin-top: 4px;
        color: $R500;
        line-height: 16px;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding-left: 4px;

        i {
            margin-right: 5px;
        }
    }

    &-field {
        margin-bottom: space(4);
        > label {
            color: #333;
            font-weight: 500;
            margin-bottom: space(2);
            line-height: 18px;
            display: flex;
            font-size: 14px;
            align-items: center;
        }

        span.required {
            color: $red;
            margin-left: 4px;
            font-size: 16px;
            display: inline-block;
            line-height: 15px;
        }
    }
    &-field-set {
        margin-bottom: space(3);
        h4 {
            font-size: 16px;
            font-weight: 700;
            color: #000;
            line-height: 20px;
        }
    }
}

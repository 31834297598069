@import '../../../assets/styles/variables/colors';
@import '../../../assets/styles/variables/variables';
@import '../../../assets/styles/functions/functions';

.sidebarMenu {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    >ul {
        >li {
            position: relative;
            margin-top: space(0.5);

            >svg {
                position: absolute;
                right: space(2);
                top: space(3);
                cursor: pointer;
                z-index: 100
            }

            >a {
                display: flex;
                align-items: center;
                line-height: 48px;
                min-height: 48px;
                padding: 0 space(2);
                color: #212121;
                font-size: 12px;
                font-weight: 500;
                border-radius: radius(2);
                transition: 0.15s;

                >span {
                    margin-left: space(3);
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                >svg {
                    flex: 0 0 20px;
                }

                &.active {
                    background: rgba(56, 97, 251, 0.08);
                    color: $button-primary-color;

                    >svg {
                        stroke: $button-primary-color;
                    }
                }
            }
            &:hover {
                > a {
                    background: rgba(56, 97, 251, 0.08);
                    color: $button-primary-color;

                    >svg {
                        stroke: $button-primary-color;
                    }
                }
            }

            >span {
                cursor: pointer;
            }

            ul {
                margin-left: space(4);

                >li {
                    position: relative;
                    margin-top: space(0.5);

                    >a {
                        display: flex;
                        align-items: center;
                        line-height: 40px;
                        padding: 0 space(2);
                        color: #212121;
                        font-size: 12px;
                        font-weight: 400;
                        border-radius: radius(2);
                        transition: 0.15s;

                        >span {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }

                        &:before {
                            content: '';
                            position: absolute;
                            left: space(4);
                            top: 20px;
                            transform: translateY(-50%);
                            width: 13px;
                            height: 13px;
                            border: 1px solid #CACACA;
                            border-radius: 100%;
                        }

                        &.active {
                            background: rgba(56, 97, 251, 0.08);
                            color: $button-primary-color;
                            &:before {
                                border-color: $button-primary-color;
                            }
                        }
                    }
                    &:hover {
                        > a {
                            background: rgba(56, 97, 251, 0.08);
                            color: $button-primary-color;
        
                            >svg {
                                stroke: $button-primary-color;
                            }
                        }
                    }

                    >svg {
                        position: absolute;
                        right: space(2);
                        top: space(2);
                        cursor: pointer;
                        z-index: 100
                    }
                }
            }

            >ul {
                >li {
                    >a {
                        padding-left: 37px;
                    }

                    ul {
                        margin-left: space(4);

                        li {
                            margin-top: space(0.5);

                            >a {
                                padding-left: 37px;

                                &:before {
                                    border-radius: radius(1);
                                }
                            }
                        }
                    }
                }
            }
        }

        ul {
            display: none;
        }

        li {
            &.active {
                >ul {
                    display: block;
                }
            }
        }
    }

    &.minimized {
        >ul {
            >li {
                
                >ul {
                    display: block;
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                    left: calc(100% + 45px);
                    top: 0;
                    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.10);
                    background: #FFF;
                    border-radius: radius(2);
                    padding: space(2);
                    width: 228px;
                    margin: 0;
                    margin-left: -10px;
                    transition: 0.15s 0.1s;

                    &:before {
                        content: attr(data-title);
                        line-height: 34px;
                        margin-bottom: space(1);
                        padding: 0 space(2);
                        color: #212121;
                        font-size: 14px;
                        font-weight: 400;
                        display: block;
                    }
                }

                &:hover {
                    &:after {
                        position: absolute;
                        top: 0;
                        left: 100%;
                        width: 45px;
                        bottom: 0;
                        content: '';
                    }

                    >ul {
                        opacity: 1;
                        visibility: visible;
                        margin-left: 0;
                    }
                }
            }

            >li {
                >svg {
                    display: none;
                }

                >a {
                    padding: 0 14px;

                    span {
                        display: none;
                    }
                }
            }
        }
    }
}
.general-error-message {
    color: red;
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    >span {
        &:first-child {
            padding-right: 4px;
            display: flex;
            align-items: center;
        }
    }
}
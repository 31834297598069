@import "src/assets/styles/functions/functions";
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/variables/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;

  &Field {
    width: 100%;
  }
}

.dateWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: space(1) 0 0 0;

  span {
    font-size : 14px;
    color : rgba(34, 34, 34, 0.4);
  }

  &Field {
    width: 100%;
    margin-top: space(2);
  }

  ul {
    display: flex;

    li {
      font-size: 14px;
      font-weight: 400;
      margin-left: space(1);
      padding: space(2) space(3);
      border: 1px solid rgba(237, 237, 237, 1);
      color: rgba(136, 136, 136, 1);
      border-radius: radius(2);
      cursor: pointer;

      &.active {
        background: rgba(62, 63, 68, 1);
        color: rgba(237, 237, 237, 1);
      }
    }
  }
}

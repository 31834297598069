@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/variables/colors';

.body{
    width: 100%;

    .selectorWrapper{
        display: flex;
        flex-direction: column;

        .errorMessageWrapper{
            background-color: rgb(255, 234, 234);
            padding: $spacing-2 $spacing-2;
            border-radius: $border-radius-1;
            span{
                font-weight: 700;
                color: red;
            }
        }

        .title{
            color: #212121;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: $spacing-2 0;
        }

        .itemName{
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: $spacing-2 0;
        }

        .description{
            color: #212121;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: $spacing-2 0;
        }

        .searchBoxTitle{
            margin: $spacing-2 0;
            color: #212121;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

    }

    .footer {
        margin-top: $spacing-8;
        display: flex;
        align-items: center;

        button {
            flex: 0 1 100%;
        }
    }
}
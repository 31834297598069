@import '../../../assets/styles/variables/colors';
@import '../../../assets/styles/variables/variables';
@import '../../../assets/styles/functions/functions';

.button,
a.button {
    margin: 0 8px;
    padding: 0 15px;
    line-height: 56px;
    color: $N700;
    font-size: 14px;
    font-weight: 600;
    display: inline-flex;
    justify-content: center;
    cursor: pointer;
    border: 1px solid transparent;
    background-color: transparent;
    transition: 0.15s;
    text-align: center;
    border-radius: $border-radius-2;
    box-shadow: unset;

    $button: &;

    &--xs {
        line-height: 20px;
        height: 20px;
        padding: 0 8px;
        font-weight: 400;
        font-size: 10px;
        border-radius: $border-radius-2;
    }

    &--s {
        line-height: 32px;
        height: 32px;
        padding: 0 16px;
    }

    &--m {
        line-height: 40px;
        height: 40px;
        padding: 0 24px;
    }

    &--l {
        line-height: 46px;
        height: 46px;
        padding: 0 30px;
    }

    &--right {
        margin-left: auto;
    }

    &--left {
        margin-right: auto;
    }

    &--loading {
        padding-right: 50px;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            right: 12px;
            top: 50%;
            margin-top: -11px;
            width: 20px;
            height: 20px;
            border: 2px solid;
            border-color: $N0 $N0 transparent;
            border-radius: 50%;
            animation: rotation 0.75s linear infinite;
        }
    }

    &--full {
        width: 100%;
    }

    &--primary {
        border-color: $button-primary-color;
        background-color: $button-primary-color;
        color: $N0;

        &:hover {
            color: $N0;
            opacity: 0.9;
        }

        &:active {
            color: $N0;
            border-color: $button-primary-color;
            background-color: $button-primary-color;
        }

        &:disabled {
            opacity: 0.5;
        }
    }

    &--secondary {
        border-color: $button-secondary-border-color;
        background-color: $N0;
        color: $button-primary-color;

        &:hover {
            color: $N700;
            border-color: $N300;
            background-color: $N50;
        }

        &:active {
            color: $N0;
            border-color: $button-primary-color;
            background-color: $button-primary-color;

            svg {
                stroke: $N0;
            }
        }

        svg {
            stroke: $button-primary-color;
        }

        &:disabled {
            color: $N200;
            opacity: 0.6;

            &:hover,
            &:active {
                opacity: 0.6;
                border-color: $button-secondary-border-color;
                color: $N200;
                background-color: $N0;
            }
        }
    }

    &--quaternary {
        border-color: transparent;
        background-color: rgba(56, 97, 251, 0.08);
        color: $button-primary-color;

        &:hover {
            color: $N0;
            border-color: transparent;
            background-color: $button-primary-color;
        }

        &:active {
            opacity: 0.75;
            color: $N0;
            border-color: transparent;
            background-color: $button-primary-color;
        }

        &:disabled {
            color: $N200;
            opacity: 0.6;
            background-color: rgba(56, 97, 251, 0.08);

            &:hover {
                color: $N200;
                border-color: transparent;
                background-color: rgba(56, 97, 251, 0.08);
            }

            &:active {
                opacity: 0.6;
            }
        }
    }

    &--tertiary {
        background-color: #3861fb14;
        color: $button-primary-color;

        &:hover {
            opacity: 0.8;
        }

        &:disabled {
            color: $N200;
            opacity: 0.6;
        }
    }

    &--danger {
        background-color: #DC4E4E;
        color: $N0;

        &:hover {
            opacity: 0.8;
        }

        &:disabled {
            color: $N200;
            opacity: 0.6;
        }
    }

    &--icon-only {
        padding: space(1.75);
        border-radius: radius(3);

        svg {
            width: 24px;
            height: 24px;
        }

        &.button--loading {
            padding: space(1.75);
            position: relative;

            svg {
                opacity: 0;
            }

            &:before {
                border-color: $button-primary-color transparent;
                right: 7px;
                border-color: $button-primary-color $button-primary-color transparent;
            }

        }
    }

    &:disabled,
    &--disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.6;

        &:hover {
            opacity: 0.6;
        }
    }

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}
@import 'src/assets/styles/functions/functions';
@import 'src/assets/styles/variables/colors';

.popover {
    min-width: 326px;
    position: relative;
    padding: space(3) space(3) space(6) space(17);
    background-color: #fff;
    border-radius: radius(3);
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
    border-bottom: 4px solid #eee;

    > svg {
        position: absolute;
        left: space(3);
        top: space(3);
        width: 40px;
        height: 40px;
        color: #ddd
    }

    h4 {
        color: #111;
        font-size: 18px;
        font-weight: 700;
        line-height: 30px;
        margin-bottom: space(2);
    }

    p {
        color: #323232;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
    }

    ul {
        list-style: disc;
        padding-left: space(4);
        color: #323232;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
    }

    &.info {
        border-bottom-color: #3861fb;
        h4, svg {
            color: #3861fb;
        }
    }
    &.warning {
        border-bottom-color: #fda92c;
        h4, svg {
            color: #fda92c;
        }
    }
    &.error {
        border-bottom-color: #f25325;
        h4, svg {
            color: #f25325;
        }
    }
}

.customTooltip {
    :global {
        .p-tooltip-text {
            background-color: unset !important;
            padding: unset !important;
        }
        .p-tooltip-arrow {
            display: none !important;
        }
    }
}

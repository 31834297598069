@import '../../../assets/styles/variables/colors';
@import '../../../assets/styles/variables/variables';
@import '../../../assets/styles/functions/functions';

.dashboardTable {
    $dashboard-table: &;
    margin-bottom: space(3);

    &:last-child {
        margin-bottom: 0;
    }

    &Header {
        position: relative;
        margin-bottom: space(3);
        display: flex;
        align-items: center;
        justify-content: space-between;

        &Right {
            display: flex;
            gap: space(3);
        }

        &RowPerPage {
            display: flex;
            align-items: center;
            gap: space(3);

            span {
                white-space: nowrap;
                font-size: 14px;
            }

            :global {
                .p-dropdown {
                    border: 1px solid $divider-color;
                    padding: 0 0 0 space(2);
                    line-height: 46px;
                    height: 48px !important;
                    border-radius: radius(2);

                    .p-dropdown-label {
                        padding: 0;
                        color: $N900;
                        font-size: 14px;
                    }
                }

                .p-inputtext {
                    line-height: 46px !important;
                }
            }
        }

        &Button {
            line-height: 46px;
            padding: 0 space(3);
            white-space: nowrap;
            display: flex;
            align-items: center;
            border: 1px solid #EAEAEA;
            border-radius: radius(2);
            font-size: 16px;
            font-weight: 500;
            gap: space(2);
            cursor: pointer;

            svg {
                width: 24px;
            }

            &.active {
                border-color: #EFF2FF;
                background-color: #EFF2FF;
                color: $button-primary-color;
            }
        }
    }

    &Filter {
        border-radius: radius(3);
        border: 1px solid #E8E8E8;
        background: #FFF;
        padding: space(3) space(3) space(1.5);
        margin-bottom: space(3);
        display: none;

        &.active {
            display: block;
        }

        &Header {
            display: flex;
            margin-bottom: space(4);

            >span {
                font-size: 16px;
                font-weight: 500;
            }

            div {
                margin-left: auto;
            }
        }
    }

    &Customize {
        border-radius: radius(3);
        border: 1px solid #E8E8E8;
        background: #FFF;
        padding: space(3);
        margin-bottom: space(3);

        &Header {
            margin-bottom: space(5);

            span {
                color: #000;
                font-size: 20px;
                font-weight: 500;
                line-height: 25px;
                margin-bottom: space(2);
                display: block;
            }

            p {
                color: rgba(0, 0, 0, 0.60);
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                margin-bottom: 0;
            }
        }

        &Items {
            display: flex;
            gap: space(3)
        }

        &Item {
            border-radius: radius(2);
            border: 1px solid #E8E8E8;
            background: #F8F8F8;
            color: #676767;
            padding: 0 space(2);
            line-height: 38px;
            cursor: pointer;
            font-size: 16px;

            svg {
                margin-left: space(2);
                width: 8px;
            }

            &.active {
                color: $button-primary-color;
                background: rgba(56, 97, 251, 0.08);
                border-color: transparent;
            }
        }
    }



    &Content {
        background-color: $N0;
        border-radius: $border-radius-2;
        border: 1px solid $primary-border-color;
        position: relative;
        margin-bottom: space(6);
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &Message {
            height: 600px;
            overflow: hidden;
        }
        &::-webkit-scrollbar {
            display: none;
            /* Safari and Chrome */
        }
    }

    &Footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &Info {
            margin-right: auto;
            color: $N500;
            font-size: 13px;
            font-weight: 600;
        }

        &Paginator {
            padding: 0;
        }
    }



    :global {
        .p-dropdown {
            height: 40px;

            .p-dropdown-trigger {
                width: 36px;
            }
        }

        .p-inputtext {
            line-height: 38px;
        }

        .p-filter-column {
            .p-column-filter {
                .p-column-filter-element {
                    padding: $spacing-2 0;
                }

                .p-inputtext {
                    line-height: 35px;
                    font-size: 14px;
                }

                button {
                    display: none;
                }
            }
        }
    }

    &Loading {
        :global {
            .p-datatable-tbody {
                z-index: 50;
                position: relative;

                &:after {
                    content: '';
                    background-color: rgba(255, 255, 255, 0.5);
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    backdrop-filter: blur(3px);
                    z-index: 1;
                }

                &:before {
                    content: '';
                    z-index: 2;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-top: -18px;
                    margin-left: -18px;
                    width: 36px;
                    height: 36px;
                    border: 5px solid;
                    border-color: $button-primary-color transparent;
                    border-radius: 50%;
                    display: inline-block;
                    animation: rotation 1s linear infinite;
                }
            }

            @keyframes :global(rotation) {
                0% {
                    transform: rotate(0deg)
                }

                100% {
                    transform: rotate(360deg)
                }
            }
        }
    }

    &Message {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,0.6);
        z-index: 4;
        user-select: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 44px;
        transition: 1s ease all;


        &Active {
            opacity: 1;
            visibility: visible;
        }

        &::before {
            content: "";
            background-color: rgba(255,255,255,0.6);
            filter: blur(88px);
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }        
    }
}

.dashboard-filters {
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 5px;
    width: 244px;
    border: 1px solid #E7E7E7;
    border-radius: $border-radius-2;
    padding: 12px 8px;
    background-color: $N0;
    display: none;

    &.active {
        display: block;
    }
}
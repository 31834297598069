@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';

.container{
    min-height: 142px;
    width: 100%;
    position: absolute;
    background-color: $N0;
    top: 0px;
    border-radius: $border-radius-3;
    border-bottom: 7px solid; 
    padding: $spacing-3;
    display: flex;
    flex-direction: row;

    .body{
        margin-left: $spacing-3;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        .title{
            font-size: 18px; // not defined in variables
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
        }

        div>p{
            margin-top: $spacing-2;
            color: #323232;
            font-size: $font-size-3;
            font-style: normal;
            font-weight: 500;
            line-height: normal; 
        }

        .actionWrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .checkBoxWrapper{
                margin-top: $spacing-2;
                .checkboxText{
                    color: rgba(0, 0, 0, 0.56);
                    font-size: $font-size-2;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-left: $spacing-2;
                }
            }
            .buttonWrapper{
                .text{
                    color: #FFF;
                    font-size: $font-size-2;
                    text-transform: capitalize;
                    font-family: $body-font-family;
                }
            }
        }
    }
}
@import '../../assets/styles/variables/colors';
@import '../../assets/styles/variables/variables';
@import '../../assets/styles/functions/functions';

.dashboard-box {
    background-color: $N0;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: $border-radius-2;
    padding: space(4);

    &__title {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: space(4);

        >span {
            font-size: 16px;
            font-weight: 700;
            margin-right: auto;
        }

        :global {
            .button {
                margin-left: auto;
            }
        }
    }
    &__title-suffix
    {
        margin-left: auto;
        display: flex;
        align-items: center;
        > span
        {
            margin-right: $spacing-2;
            color: $N400;
        }
    }

    &__content {
        flex: 0 1 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &--inner {
        box-shadow: unset;
        border: 1px solid $primary-border-color;
    }
    &:last-child
    {
        margin-bottom: 0;
    }
}
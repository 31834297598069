@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/functions/functions';

.wrapper {
    height: 910px;
    width: 90vw;
    padding: space(2);

    .body {
        line-height: 20px;
        font-size: 13px;
        height: 500px !important;
    }

    .footer {
        margin-top: space(6);
        display: flex;
        align-items: center;

        button {
            flex: 0 1 100%;
        }
    }
}
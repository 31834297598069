@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/functions/functions';

.formFieldRadio {
    display: flex;
    align-items: center;

    &Title {
        label {
            display: block;
            color: #333;
            font-weight: 500;
            margin-bottom: 6px;
            line-height: 15px;

            span {
                color: $red;
                margin-left: 4px;
                font-size: 16px;
                display: inline-block;
                line-height: 15px;
            }
        }
        :global {
            .form-input-error {
                margin-top: space(2);
                display: flex;
                align-items: center;
                gap: space(1);
                padding: 0;
            }
            
            p:not(.form-input-error) {
                color: rgba(33, 33, 33, 0.56);
                margin: 0;
            }
        }
    }

    &Inputs {
        display: flex;
        align-items: center;
        margin-left: auto;
    }
}
@import 'src/assets/styles/variables/colors';
@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/functions/functions';

.header {
    padding: $spacing-4 $spacing-8;
    display: flex;
    align-items: center;

    &Title {
        display: flex;
        flex-direction: column;

        >h3 {
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
        }
    }

    &Desc {
        margin-top: space(2);
        display: flex;
        justify-content: center;
        align-items: center;

        >span {
            color: rgba(0, 0, 0, 0.60);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        a {
            margin-left: space(2);
            padding-right: space(1);
            font-size: 16px;
        }
    }

    &Suffix {
        margin-left: auto;
    }

    &Link {
        display : flex;
        justify-content : center;
        align-items : center;
        padding-left : 2px;
        color: #3861FB;

        &:visited {
            color: #3861FB;
        }
    }

    &Icon {
        display : inline-block;
        padding : space(1) 0 0 space(1);

        svg {
            font-size: 20px;
            fill: #3861FB;
        }
    }

}

.body {
    padding-bottom: $spacing-14;
}

.segmentWrapper {
    padding: space(3) 0;
    width: 100%;

    .row {
        border: 1px solid $primary-border-color;
        padding: $spacing-3;

        .logoContainer {
            display: flex;
            flex-direction: row;
            align-items: center;

            .logoCard {
                width: 78px;
                height: 78px;
                border-radius: $spacing-6;
                border: 1px solid #E8E8E8;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 45px;
                    height: 45px;
                    border-radius: $border-radius-1;
                }
            }

            .nameWrapper {
                display: flex;
                flex-direction: column;
                margin-left: $spacing-3;

                h3 {
                    color: #212121;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: capitalize;
                }

                h4 {
                    color: #888;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                }
            }
        }

        .segment {
            display: flex;
            flex-direction: column;

            .title {
                color: rgba(34, 34, 34, 0.40);
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
            }

            .detail {
                color: #212121;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-top: $spacing-3;
                word-wrap: break-word;
            }

            .locationWrapper {
                display: flex;
                justify-content: flex-end;
            }

            .tagWrapper {
                display: flex;
                justify-content: start;
                gap: $spacing-6;
                flex-wrap: wrap;
            }

            .tag {
                font-size: 16px;
                background-color: rgba(248, 248, 248, 1);
                border-radius: 8px;
                padding: $spacing-1 $spacing-2;
                border : 1px solid rgba(232, 232, 232, 1);
            }
        }

        .segmentRtl{
            @extend .segment;
            direction: rtl;
        }
    }

    .topBorderRadius {
        border-top-left-radius: $border-radius-2;
        border-top-right-radius: $border-radius-2;
    }

    .topLeftBorderRadius {
        border-top-left-radius: $border-radius-2;
    }

    .bottomLeftBorderRadius {
        border-bottom-left-radius: $border-radius-2;
    }

    .bottomRightBorderRadius {
        border-bottom-right-radius: $border-radius-2;
    }

    .topRightBorderRadius {
        border-top-right-radius: $border-radius-2;
    }

    .noBorderTop {
        border-top: none;
    }

    .noBorderLeft {
        border-left: none;
    }

    .fullBorderRadius {
        border-radius: $border-radius-2;
    }

    .bottomBorderRadius {
        border-bottom-left-radius: $border-radius-2;
        border-bottom-right-radius: $border-radius-2;
    }
}
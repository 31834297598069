@import 'src/assets/styles/variables/colors';

.imageUploader {
    display: flex;
    align-items: center;

    &Content {
        display: flex;
        flex-direction: column;
        padding-left: 16px;

        label {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 8px;
        }

        p {
            color: #888;
            font-size: 12px;
            font-weight: 400;
        }
    }
}
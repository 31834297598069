@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/variables/colors';

.mainContent {
    $mainContent: &;
    max-width: 100vw;
    height: 100vh;
    position: relative;
    padding-left: $sidebar-width;
    transition: 0.15s;

    .bodyWrapper {
        .dashboardContent {
            flex: 1;
            background-color: #FFF;
            height: calc(100vh - $header-height);
            overflow-y: auto;
            overflow-x: hidden;
            position: relative;
        }
    }

    &.minimizeMenu {
        padding-left: $sidebar-minimized-width;
    }
}
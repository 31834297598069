@import 'src/assets/styles/variables/variables';
@import 'src/assets/styles/variables/colors';

.sidePanelWrapper {
    position: fixed;
    top: $header-height;
    bottom: 0px;
    right: 0px;
    background-color: $N0;
    border: 1px solid $primary-border-color;
    width: 300px;
    transition: right 0.1s ease-in-out;
    padding: $spacing-4 $spacing-6;
    z-index: 10;
    transition: 0.15s;

    .sidePanelHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        span {
            color: N700;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            text-transform: capitalize;
        }

        .closeButtonWrapper {
            padding: $spacing-1;
            cursor: pointer;
        }
    }
}

.sidePanelWrapperHide {
    position: absolute;
    right: -300px;
    background-color: $N0;
    border: 1px solid $primary-border-color;
    height: 100%;
    width: 300px;
    transition: right 0.1s ease-in-out;
    padding: $spacing-4 $spacing-6;
}

.sidePanelBackground{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(1px);
    backdrop-filter: blur(1px);
}
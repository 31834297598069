@import '../../../assets/styles/variables/colors';
@import '../../../assets/styles/variables/variables';
@import '../../../assets/styles/functions/functions';

.multiStepForm {
    &Header {
        display: flex;
        align-items: center;
        padding: space(4) space(8);
        background-color: #FFF;
        position: sticky;
        top: 0;
        z-index: 5;
    }

    &Title {
        display: flex;
        flex-direction: column;

        h3 {
            font-size: 20px;
            font-weight: 500;
            line-height: 25px;
            margin-bottom: space(1);
        }

        p {
            margin: 0;
            color: rgba(0, 0, 0, 0.60);
            font-size: 16px;
            line-height: 20px;
        }
    }

    &Buttons {
        margin-left: auto;
        display: flex;
    }

    &Controller {
        border-bottom: 1px solid #E8E8E8;
        background-color: #fff;
        position: sticky;
        top: 81px;
        z-index: 5;

        ul {
            display: flex;
            align-items: center;
            justify-content: center;

            li {
                padding: space(3) space(6);
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 16px;
                line-height: 44px;
                cursor: pointer;
                color: $tab-primary-color;
                margin-right: calc(50px + space(2));
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    right: calc(100% + space(1));
                    top: 50%;
                    background-color: rgba(197, 197, 197, 0.85);
                    width: 50px;
                    height: 1px;
                }

                small {
                    width: 24px;
                    height: 24px;
                    border-radius: radius(2);
                    background-color: $tab-primary-color;
                    text-align: center;
                    line-height: 24px;
                    color: #fff;
                    font-size: 12px;
                    margin-right: space(3);
                }

                &.active {
                    color: $button-primary-color;

                    small {
                        background-color: $button-primary-color;
                    }

                    &:before {
                        background-color: $button-primary-color;
                    }

                    &:hover {
                        background-color: #f8f8f8
                    }
                }

                &.disabled {
                    cursor: default;
                    color: rgba(197, 197, 197, 0.72);

                    small {
                        background-color: rgba(197, 197, 197, 0.72);
                    }
                }

                &.hasError {
                    position: relative;

                    &:after {
                        content: '';
                        right: space(1.5);
                        width: 10px;
                        top: calc(50% - 5px);
                        height: 10px;
                        border-radius: 5px;
                        background-color: #F25325;
                        position: absolute;
                    }
                }

                &:first-of-type {
                    &:before {
                        display: none;
                    }
                }

                &:last-of-type {
                    margin-right: unset;
                }

            }
        }
    }

    &Content {
        background-color: #fff;
        padding: space(17.5) 0;
    }

    &Errors {
        padding: space(3) space(3) space(4.25) space(17);
        border-radius: radius(3);
        border-bottom: 7px solid #F25325;
        background: #FFF;

        h4 {
            color: #F25325;
            font-size: 18px;
            font-weight: 700;
            margin-bottom: space(2);
        }

        ul {
            line-height: 24px;
            color: #323232;
            font-size: 16px;
            font-weight: 500;
            list-style: disc;
            padding-left: space(6);
        }
    }
}
@import '../../assets/styles/variables/variables';
@font-face {
     font-family: 'teaser';
     src:  url('../fonts/icons/teaser.eot?ax6zxe');
     src:  url('../fonts/icons/teaser.eot?ax6zxe#iefix') format('embedded-opentype'),
       url('../fonts/icons/teaser.ttf?ax6zxe') format('truetype'),
       url('../fonts/icons/teaser.woff?ax6zxe') format('woff'),
       url('../fonts/icons/teaser.svg?ax6zxe#teaser') format('svg');
     font-weight: normal;
     font-style: normal;
     font-display: block;
   }
   
   [class^="ts-"], [class*=" ts-"] {
     /* use !important to prevent issues with browser extensions that change fonts */
     font-family: 'teaser' !important;
     speak: never;
     font-style: normal;
     font-weight: normal;
     font-variant: normal;
     text-transform: none;
     line-height: 1;
   
     /* Better Font Rendering =========== */
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
   }
   
   .ts-log:before {
     content: "\e900";
   }
   .ts-jet:before {
     content: "\e901";
   }
   .ts-attach:before {
     content: "\e902";
   }
   .ts-bug:before {
     content: "\e903";
   }
   .ts-company:before {
     content: "\e904";
   }
   .ts-dashboard:before {
     content: "\e905";
   }
   .ts-edit:before {
     content: "\e906";
   }
   .ts-fund:before {
     content: "\e907";
   }
   .ts-up-double:before {
     content: "\e908";
   }
   .ts-chart:before {
     content: "\e909";
   }
   .ts-down:before {
     content: "\e90a";
   }
   .ts-down-double:before {
     content: "\e90b";
   }
   .ts-mna:before {
     content: "\e90c";
   }
   .ts-equal:before {
     content: "\e90d";
   }
   .ts-new:before {
     content: "\e90e";
   }
   .ts-notification:before {
     content: "\e90f";
   }
   .ts-user:before {
     content: "\e910";
   }
   .ts-private-projects:before {
     content: "\e911";
   }
   .ts-public-tender:before {
     content: "\e912";
   }
   .ts-qa:before {
     content: "\e913";
   }
   .ts-search:before {
     content: "\e914";
   }
   .ts-show-hide:before {
     content: "\e915";
   }
   .ts-ticket:before {
     content: "\e916";
   }
   .ts-user-management:before {
     content: "\e917";
   }
   .ts-profile:before {
     content: "\e918";
   }
   .ts-logout:before {
     content: "\e919";
   }
   .ts-left:before {
     content: "\e91a";
   }
   .ts-right:before {
     content: "\e91b";
   }
   .ts-up:before {
     content: "\e91c";
   }
   .ts-filter:before {
     content: "\e91d";
   }
   .ts-camera:before {
     content: "\e91e";
   }
   .ts-blog:before {
     content: "\e91f";
   }
   .ts-disclosure:before {
     content: "\e920";
   }
   .ts-news:before {
     content: "\e921";
   }
   .ts-check:before {
     content: "\e922";
   }
   .ts-info:before {
     content: "\e923";
   }
   .ts-error:before {
     content: "\e924";
   }
   .ts-support:before {
     content: "\e925";
   }
   .ts-trash:before {
     content: "\e926";
   }
   .ts-plus:before {
     content: "\e927";
   }
   
@font-face {
     font-family: 'DM Sans';
     font-style: normal;
     font-weight: 400;
     src: local('DM Sans Regular'), url('../fonts/dm-sans/DMSans-Regular.woff') format('woff');
}

@font-face {
     font-family: 'DM Sans';
     font-style: italic;
     font-weight: normal;
     src: local(''), url('../fonts/dm-sans/DMSans-Italic.woff') format('woff');
}

@font-face {
     font-family: 'DM Sans';
     font-style: normal;
     font-weight: 500;
     src: local(''), url('../fonts/dm-sans/DMSans-Medium.woff') format('woff');
}

@font-face {
     font-family: 'DM Sans';
     font-style: italic;
     font-weight: 500;
     src: local(''), url('../fonts/dm-sans/DMSans-MediumItalic.woff') format('woff');
}

@font-face {
     font-family: 'DM Sans';
     font-style: normal;
     font-weight: 600;
     src: local(''), url('../fonts/dm-sans/DMSans-Bold.woff') format('woff');
}

@font-face {
     font-family: 'DM Sans';
     font-style: italic;
     font-weight: 600;
     src: local(''), url('../fonts/dm-sans/DMSans-BoldItalic.woff') format('woff');
}
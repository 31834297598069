@import '../../../../assets/styles/functions/functions';
@import '../../../../assets/styles/variables/colors';

.fileBtn {
    width : 100%;
    height: 43px;
    font-weight : 100;
    display : flex;
    justify-content : space-between;

    span {
        font-size: 18px;
    }
}
.fileUploader {
    position: relative;
    $fileUploader: &;

    :global {
        .p-button-icon {
            order: 2;
            margin-left: space(2);
            margin-right: unset;
        }
    }

    &Clear {
        position: absolute;
        bottom: 7px;
        width: 30px;
        height: 30px;
        padding: 3px;
        border-radius: 50%;
        left: calc(100% + 10px);
        background-color: #eee;
        stroke: $button-primary-color;
        transition: 0.15s;
        cursor: pointer;

        &:hover {
            background-color: $button-primary-color;
            stroke: #fff;
        }
    }

    &FilePreview {
        position: relative;

        .downloaderElement {
            display: flex;
            align-items: center;
            background-color: #fff;
            border-radius: radius(2);
            line-height: 41px;
            border: 1px solid $button-primary-color;
            padding: 0 space(2);
            color: #444;
            cursor: pointer;

            span {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            img {
                width: 25px;
                height: 25px;
                margin-right: space(2);
            }

            &:hover {
                background-color: #eee;
            }
        }

        &Clear {
            position: absolute;
            bottom: 7px;
            width: 30px;
            height: 30px;
            padding: 3px;
            border-radius: 50%;
            left: calc(100% + 10px);
            background-color: #eee;
            stroke: $button-primary-color;
            transition: 0.15s;
            cursor: pointer;

            &:hover {
                background-color: $button-primary-color;
                stroke: #fff;
            }
        }
    }

    &Tooltip {
        position: absolute;
        bottom: calc(80% - 10px);
        left: calc(50% - 20px);
        border-radius: radius(1);
        transform: translateX(-50%);
        padding: space(2) space(3);
        background-color: #fff;
        box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
        z-index: 100;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        transition: 0.25s;
        font-weight: 300;
        line-height: 20px;

        &:before {
            content: '';
            border-top: 8px solid #fff;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &:hover {
        #{$fileUploader}Tooltip {
            opacity: 1;
            bottom: calc(100% - 10px);
            visibility: visible;
        }
    }
}
@import './variables/colors';
@import './variables/variables';
@import './functions/functions';

.pi {
    font-size: 12px;
}

.p-tooltip {
    .p-tooltip-text {
        padding: space(1) space(2);
        border-radius: radius(2);
        color: rgba(255, 255, 255, 0.7);
        background: rgba(50, 50, 50, 1);
        box-shadow: none;
        font-size: 12px;
    }
    &.p-tooltip-bottom .p-tooltip-arrow {
        border-bottom-color: rgba(50, 50, 50, 1);
    }
    &.p-tooltip-right .p-tooltip-arrow {
        border-right-color: rgba(50, 50, 50, 1);
    }
    &.p-tooltip-left .p-tooltip-arrow {
        border-left-color: rgba(50, 50, 50, 1);
    }
    &.p-tooltip-top .p-tooltip-arrow {
        border-top-color: rgba(50, 50, 50, 1);
    }
}

.p-dropdown {
    width: 100%;
    line-height: 48px;
    font-size: 14px;
    border-radius: radius(2);
    border-color: rgba(197, 197, 197, 0.4);

    &:not(.p-disabled):hover {
        border-color: #ababab;
    }

    &:not(.p-disabled).p-focus {
        outline: unset;
        box-shadow: unset;
        border-color: $button-primary-color;
    }

    &-panel {
        border-radius: radius(2);
        box-shadow: unset;
        border: 1px solid #ededed;
        margin-top: space(1);

        .p-dropdown-header {
            padding: space(2) space(2) space(1) space(2);
            background: #fff;
            border: none;
        }

        .p-dropdown-items {
            padding: space(2);

            .p-dropdown-item {
                line-height: 40px;
                border-radius: radius(2);
                padding: 0 space(2);
                font-size: 14px;
                margin-bottom: space(0.5);

                &:not(.p-highlight):not(.p-disabled):hover {
                    background: rgba(197, 197, 197, 0.2);
                }

                &:first-child {
                    margin-top: 0;
                }

                &:last-of-type {
                    margin-bottom: unset;
                }
            }
        }
    }
}
.p-multiselect {
    @extend .p-dropdown;
    .p-multiselect-label {
        color: #495057;
    }
}
.p-multiselect .p-multiselect-label {
    padding: 0 space(2);
}

.p-multiselect-panel {
    .p-multiselect-items {
        padding: space(2);
        .p-multiselect-item {
            line-height: 40px;
            border-radius: space(2);
            padding: 0 space(2);
            font-size: 14px;
            margin-bottom: 2px;
            color: #495057;
            &:not(.p-highlight):not(.p-disabled):hover {
                background-color: rgba(197, 197, 197, 0.2);
            }
        }
    }
}

.p-chips {
    .p-chips-multiple-container {
        .p-chips-token {
            line-height: 24px;
        }
        .p-chips-input-token {
            input {
                font-size: 14px;
            }
        }
        &:not(.p-disabled) {
            &:hover {
                border-color: #ababab;
            }
        }
    }
    
}

.p-calendar {
    width: 100%;
}

.p-datatable {
    .p-datatable-wrapper {
        table {
            thead {
                > tr {
                    > th {
                        line-height: 40px;
                        background: #f8f8f9;
                        border: unset;
                        color: $N850;
                        padding: space(3) space(2);
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 24px;
                        white-space: nowrap;

                        &:first-child {
                            padding-left: 12px;
                            border-radius: space(2) 0 0 0;
                        }

                        &:last-child {
                            padding-right: 12px;
                            border-radius: 0 space(2) 0 0;
                        }

                        &.p-frozen-column {
                            position: sticky;
                        }
                    }
                }
            }

            tbody {
                > tr {
                    > td {
                        border-bottom: 1px solid #e6e6e9;
                        font-size: 14px;
                        font-weight: 400;
                        transition: 0.1s;
                        line-height: 30px;
                        background-color: #fff;
                        vertical-align: middle;
                        padding: space(2);
                        white-space: nowrap;

                        p {
                            margin: 0;
                        }

                        > span {
                            display: inline-flex;
                        }

                        a {
                            &.fileIcon {
                                display: inline-flex;
                                min-height: 40px;
                                align-items: center;
                            }
                        }

                        &.p-frozen-column {
                            position: sticky;
                            box-shadow:
                                inset 1px 0 0 #e6e6e9,
                                inset -1px 0 0 #e6e6e9;
                        }

                        &:first-child {
                            padding-left: 12px;
                            &.p-frozen-column {
                                box-shadow: inset -1px 0 0 #e6e6e9;
                            }
                        }

                        &:last-child {
                            padding-right: 12px;
                            &.p-frozen-column {
                                box-shadow: inset 1px 0 0 #e6e6e9;
                            }
                        }
                    }

                    &:hover {
                        > td {
                            background-color: rgb(249, 250, 251);
                        }
                    }

                    &.has-background {
                        > td {
                            background-color: $N50;
                            font-weight: 600;
                        }
                    }

                    &:last-child {
                        td {
                            border-bottom: unset;

                            &:first-child {
                                border-radius: 0 0 0 space(2);
                            }

                            &:last-child {
                                border-radius: 0 0 space(2) 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.p-float-label {
    label {
        top: 23px;
        transition: 0.15s;
        color: $N900;
        font-size: 14px;
        font-weight: 400;
    }

    input:focus,
    input:-webkit-autofill,
    input.p-filled,
    textarea:focus,
    textarea.p-filled,
    .p-inputwrapper-focus,
    .p-inputwrapper-filled {
        ~ label {
            color: $N500;
            font-weight: 400;
            font-size: 11px;
        }
    }
}

.p-checkbox {
    margin-right: space(1);
    width: 16px;
    height: 16px;

    .p-checkbox-box {
        border: 1px solid $divider-color;
        background: $N0;
        width: 16px;
        height: 16px;
        color: $N700;
        border-radius: radius(1);
        transition:
            background-color 0.2s,
            color 0.2s,
            border-color 0.2s,
            box-shadow 0.2s;

        .p-checkbox-icon {
            font-size: 10px;
        }
    }
}

.p-button {
    &.p-button:not(button):not(a):not(.p-disabled) {
        &.p-fileupload-choose {
            width: 100%;
            background-color: #fff;
            color: $button-primary-color;
            border-color: rgba(197, 197, 197, 0.4);
            border-radius: radius(2);

            .p-button-label {
                font-size: 14px;
                font-weight: 500;
            }

            &:hover {
                background: $button-primary-color;
                color: #fff;
            }
        }
    }

    &.p-disabled {
        background-color: #eee;
        border-color: #ccc;
        color: #ccc;
        width: 100%;

        .p-button-label {
            font-size: 14px;
            font-weight: 500;
        }
    }
}

.p-togglebutton {
    line-height: 32px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    border: 1px solid $N700;
    background-color: $N0;
    color: $N700;
    padding: 0 $spacing-3;
    border-radius: $border-radius-3;

    .p-button-icon {
        margin-left: $spacing-3;
    }

    &.p-highlight {
        border-color: $V500;
        background-color: $V500;
        color: $N0;
    }
}

.p-autocomplete {
    .p-autocomplete-multiple-container {
        .p-autocomplete-token {
            padding: 0 $spacing-2;
            line-height: $line-height-4;
            background: $V50;
            color: $V500;
            border-radius: $border-radius-2;
            display: flex;
            align-items: center;
            margin-right: $spacing-3;

            .p-autocomplete-token-icon {
                margin-left: $spacing-1;
            }
        }
    }
}

.p-autocomplete-panel {
    border: 1px solid $divider-color;
    background-color: $N0;
    border-radius: $border-radius-2;
    padding: 4px 0;

    .p-autocomplete-item {
        line-height: 31px;
        padding: 0 12px;
        color: $N600;
        font-size: 14px;

        &:hover {
            background-color: $N50;
        }
    }
}

.p-inputswitch {
    width: 28px;
    height: 14px;

    .p-inputswitch-slider {
        background: $N100;
        transition:
            background-color 0.2s,
            color 0.2s,
            border-color 0.2s,
            box-shadow 0.2s;
        border-radius: 30px;

        &:before {
            background: $N0;
            width: 10px;
            height: 10px;
            left: 1px;
            margin-top: -5px;
            border-radius: 50%;
            transition-duration: 0.2s;
        }
    }

    &.p-inputswitch-checked {
        .p-inputswitch-slider {
            background-color: #65d83c;

            &:before {
                background: $N0;
                transform: translateX(14px);
            }
        }
    }
}

.p-skeleton {
    background-color: $N75;
    border-radius: $border-radius-3;
    margin-bottom: $spacing-3;

    &:after {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.p-datepicker {
    &:not(.p-datepicker-inline) {
        background: $N0;
        border: unset;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        padding: $spacing-2;
    }

    &-header {
        > button {
            background-color: transparent;
            border: unset;
            height: 35px;
            width: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $border-radius-2;

            &:hover {
                background-color: $N50;
            }
        }
    }

    &-calendar {
        text-align: center;

        thead {
            tr {
                th {
                    span {
                        line-height: 35px;
                        width: 35px;
                        display: block;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    &:hover {
                        background-color: $N50;
                    }

                    span {
                        border: 1px solid transparent;
                        line-height: 35px;
                        width: 35px;
                        display: block;
                        border-radius: $border-radius-2;

                        &.p-highlight {
                            background-color: $V500;
                            color: $N0;
                        }
                    }

                    &.p-datepicker-other-month {
                        opacity: 0.35;
                    }

                    &.p-datepicker-today {
                        span {
                            border-color: $V400;
                        }
                    }
                }
            }
        }
    }
}

.p-dialog-mask {
    background-color: rgba(0, 0, 0, 0.5);
    transition: 0.3s;
    backdrop-filter: blur(2px);
}

.p-overlaypanel {
    background-color: white;
    padding: $spacing-3 $spacing-4;
    border: 1px solid $divider-color;
    border-radius: $border-radius-3;
}

.p-dialog {
    .p-dialog-header {
        border-bottom: 0 none;
        padding: space(2) space(3);
        border-bottom: 1px solid $N75;
        align-items: center;
        border-top-right-radius: radius(2);
        border-top-left-radius: radius(2);

        .p-dialog-title {
            font-size: 16px;
            font-weight: 500;
        }
    }

    .p-dialog-header-icons {
        align-self: center;

        .pi {
            font-size: 18px;
        }
    }

    .p-dialog-content {
        background-color: $N0;
        padding: space(2) space(3);

        &:last-of-type {
            border-bottom-right-radius: radius(2);
            border-bottom-left-radius: radius(2);
        }
    }
}

.p-inputtext {
    padding: 0 8px;
    line-height: 48px;
    font-size: 14px;
    width: 100%;
    border-radius: radius(2);
    border-color: rgba(197, 197, 197, 0.4);

    &.p-disabled {
        background-color: $N50;
    }

    &:enabled:hover {
        border-color: #ababab;
    }

    &:enabled:focus {
        outline: unset;
        box-shadow: unset;
        border-color: $button-primary-color;
    }
}

.p-inputgroup-addon {
    padding: 0;
}

.p-inputnumber-negative {
    color: $red;
}

.form-error-message {
    line-height: 20px !important;
    line-height: 20px !important;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 8px;
}

.p-accordion-tab {
    .p-accordion-header {
        > a {
            box-shadow: none !important;
        }
    }
}

.p-paginator {
    padding: 0;
    .p-paginator-first,
    .p-paginator-last {
        display: none;
    }

    .p-paginator-prev,
    .p-paginator-next,
    .p-paginator-pages .p-paginator-page {
        color: $N850;
        min-width: 32px;
        font-size: 14px;
        font-weight: 700;
        width: 32px;
        height: 32px;
        padding: 0;
        border-radius: space(2);
        border: 1px solid rgba(225, 225, 225, 0.5);
        margin: 0 space(0.5);

        &.p-highlight {
            background-color: $button-primary-color;
            color: #fff;
        }
    }
}

.p-progressbar {
    height: 8px;
    .p-progressbar-value {
        background: $button-primary-color;
    }
    .p-progressbar-label {
        display: none;
    }
}

$body-font-size: 13px;
$body-font-family: DM Sans,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
$body-font-style: normal;
$sidebar-width: 322px;
$sidebar-minimized-width: 113px;
$header-height: 74px;

// Border Radiuses
$radius: 4px;
$border-radius-1: 4px;
$border-radius-2: 8px;
$border-radius-3: 16px;

// Spacings
$space: 4px;
$spacing-1: 4px;
$spacing-2: 8px;
$spacing-3: 12px;
$spacing-4: 16px;
$spacing-5: 18px;
$spacing-6: 20px;
$spacing-7: 24px;
$spacing-8: 28px;
$spacing-9: 32px;
$spacing-10: 40px;
$spacing-11: 56px;
$spacing-12: 64px;
$spacing-13: 80px;
$spacing-14: 96px;
$spacing-15: 120px;
$spacing-16: 136px;
$spacing-17: 160px;
$spacing-18: 180px;
$spacing-19: 210px;

// Break Points
$break-point-xs: 0;
$break-point-sm: 576px;
$break-point-md: 768px;
$break-point-lg: 992px;
$break-point-xl: 1200px;
$break-point-xxl: 1400px;

// fonts
$font-size-1: 12px;
$line-height-1: 20px;
$font-size-2: 14px;
$line-height-2: 22px;
$font-size-3: 16px;
$line-height-3: 24px;
$font-size-4: 20px;
$line-height-4: 28px;
$font-size-5: 24px;
$line-height-5: 32px;
$font-size-6: 30px;
$line-height-6: 38px;
$font-size-7: 38px;
$line-height-7: 46px;
$font-size-8: 46px;
$line-height-8: 54px;
$font-size-9: 56px;
$line-height-9: 64px;
$font-size-10: 68px;
$line-height-10: 76px;

.font-12 {
    font-size: $font-size-1;
    line-height: $line-height-1;
}
.font-14 {
    font-size: $font-size-2;
    line-height: $line-height-2;
}
.font-16 {
    font-size: $font-size-3;
    line-height: $line-height-3;
}
.font-20 {
    font-size: $font-size-4;
    line-height: $line-height-4;
}
.font-24 {
    font-size: $font-size-5;
    line-height: $line-height-5;
}
.font-30 {
    font-size: $font-size-6;
    line-height: $line-height-6;
}
.font-38 {
    font-size: $font-size-7;
    line-height: $line-height-7;
}
.font-46 {
    font-size: $font-size-8;
    line-height: $line-height-8;
}
.font-56 {
    font-size: $font-size-9;
    line-height: $line-height-9;
}
.font-68 {
    font-size: $font-size-10;
    line-height: $line-height-10;
}

// Icons Sizes
$icon-font-size-s: 16px;
$icon-line-height-s: 16px;
$icon-font-size-m: 20px;
$icon-line-height-m: 20px;
$icon-font-size-l: 24px;
$icon-line-height-l: 24px;

.font-icon-16 {
    font-size: $icon-font-size-s;
    line-height: $icon-line-height-s;
}
.font-icon-20 {
    font-size: $icon-font-size-m;
    line-height: $icon-line-height-m;
}
.font-icon-24 {
    font-size: $icon-font-size-l;
    line-height: $icon-line-height-l;
}
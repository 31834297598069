@import 'src/assets/styles/functions/functions.scss';
@import 'src/assets/styles/variables/colors';

.companyFinancialRatio {
    $companyFinancialRatio: &;

    &Accordion {
        :global {
            .p-accordion {
                border: 1px solid #e8e8e8;
                border-radius: radius(3);

                .p-accordion-tab {
                    margin-bottom: unset;
                }

                .p-accordion-content {
                    border: unset;
                    padding: unset;
                }

                .p-accordion-tab {
                    &.p-accordion-tab-active {
                        .p-accordion-header {
                            .p-accordion-header-link {
                                border-radius: 0
                            }
                        }
                    }

                    &:first-of-type {
                        .p-accordion-header {
                            .p-accordion-header-link {
                                border-radius: radius(3) radius(3) 0 0
                            }
                        }

                        &.p-accordion-tab-active {
                            .p-accordion-header {
                                .p-accordion-header-link {
                                    border-radius: radius(3) radius(3) 0 0
                                }
                            }
                        }
                    }

                    &:last-of-type {
                        .p-accordion-header {
                            .p-accordion-header-link {
                                border-radius: 0 0 radius(3) radius(3);
                                border-bottom: unset;
                            }
                        }

                        &.p-accordion-tab-active {
                            .p-accordion-header {
                                .p-accordion-header-link {
                                    border-radius: 0
                                }
                            }
                        }
                    }

                    &:first-of-type:last-of-type {
                        &:not(.p-accordion-tab-active) {
                            .p-accordion-header {
                                .p-accordion-header-link {
                                    border-radius: radius(3);
                                }
                            }
                        }

                        &.p-accordion-tab-active {
                            .p-accordion-header {
                                .p-accordion-header-link {
                                    border-radius: radius(3) radius(3) 0 0
                                }
                            }
                        }
                    }

                    .p-accordion-header {

                        &:not(.p-disabled).p-highlight,
                        &:not(.p-highlight):not(.p-disabled):hover {
                            .p-accordion-header-link {
                                background: rgba(56, 97, 251, 0.08);
                            }
                        }

                        &.loading {
                            .p-accordion-header-link {
                                &:before {
                                    content: '';
                                    z-index: 2;
                                    position: absolute;
                                    top: 50%;
                                    right: 160px;
                                    margin-top: -14px;
                                    width: 24px;
                                    height: 24px;
                                    border: 3px solid;
                                    border-color: $button-primary-color transparent;
                                    border-radius: 50%;
                                    display: inline-block;
                                    animation: rotation 1s linear infinite;
                                }
                            }
                        }

                        .p-accordion-header-link {
                            padding: space(2) space(3);
                            flex-direction: row-reverse;
                            background: rgba(56, 97, 251, 0.08);
                            border-width: 0 0 1px;
                            border-color: #e8e8e8;
                            border-radius: unset;

                            span {
                                margin-right: auto;
                                color: $button-primary-color;
                                font-size: 16px;
                                font-weight: 700;
                            }

                            small {
                                display: flex;
                                align-items: center;
                                background-color: #fff;
                                line-height: 40px;
                                padding: 0 space(2) 0 space(3);
                                border-radius: space(2);
                                color: #212121;
                                font-size: 16px;
                                font-weight: 500;

                                svg {
                                    margin-left: space(1);
                                    color: $button-primary-color;
                                }
                            }
                        }
                    }
                }
            }

            .p-datatable {
                .p-datatable-wrapper {
                    table {
                        thead {
                            >tr {
                                >th {

                                    &:first-child,
                                    &:last-child {
                                        border-radius: unset;
                                    }
                                }
                            }
                        }
                    }
                }

                .p-datatable-thead {
                    >tr {
                        >th {
                            background-color: #F8F8F8;
                            border-radius: unset;

                            &.detail {
                                width: 60px;
                            }
                        }
                    }
                }


                .p-datatable-tbody {
                    >tr {
                        >td {
                            color: #000;
                            font-size: 14px;
                            font-weight: 400;

                            .p-row-toggler {
                                width: 40px;
                                height: 40px;
                                border-radius: space(3);
                                border: 1px solid rgba(225, 225, 225, 0.50);
                                background-color: #fff;
                                outline: unset;
                                color: $button-primary-color;
                                transition: unset;

                                &:focus {
                                    box-shadow: unset;
                                }

                                &:enabled:hover {
                                    background-color: #fff;
                                    border-color: rgba(225, 225, 225, 0.50);
                                    color: $button-primary-color;
                                }
                            }

                            &.detail {
                                width: 60px;
                            }

                            .prefix {
                                margin-right: space(1);
                                color: #000;
                                font-size: 14px;
                                font-weight: 600;
                            }

                            .valid,
                            .invalid {
                                position: relative;
                                padding-left: space(3);

                                &:before {
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    width: 8px;
                                    height: 8px;
                                    border-radius: radius(1);
                                }
                            }

                            .invalid {
                                color: #DC4E4E;

                                &:before {
                                    background-color: #DC4E4E;
                                }
                            }

                            .valid {
                                color: #63C585;

                                &:before {
                                    background-color: #63C585;
                                }
                            }
                        }

                        &.red-bg {
                            >td {
                                background: rgba(220, 78, 78, 0.07);
                            }
                        }

                        &.p-datatable-row-expansion {
                            >td {
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    &Expanded {
        padding: space(4) space(6);
        width: 100%;
        background: rgba(226, 226, 226, 0.10);
    }

    &RelatedItems {
        display: flex;
        flex-wrap: wrap;
        margin: 0 space(-1);
    }

    &RelatedItem {
        flex: 1 1 calc(20% - space(2));
        min-width: calc(20% - space(2));
        margin: space(2) space(1) 0;
        border-radius: radius(2);
        border: 1px solid #E8E8E8;
        padding: space(2) space(3);
        display: flex;
        flex-direction: column;
        background: #FFF;
        position: relative;

        >span {
            display: block;

            &:first-of-type {
                line-height: 20px;
                font-size: 14px;
                color: #222;
                margin-bottom: space(2);
            }

            &:last-of-type {
                color: #888;
                text-overflow: ellipsis;
                font-size: 12px;
                line-height: 15px;

                &.hasError {
                    color: #F25325;
                }
            }
        }

        :global {
            .popover {
                min-width: 326px;
                position: absolute;
                padding: space(2) space(3) space(4) space(11);
                bottom: calc(100% + 10px);
                left: 0;
                background-color: #fff;
                border-radius: radius(3);
                box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.10);
                border-bottom: 4px solid #F25325;
                transition: 0.15s 0.1s;
                opacity: 0;
                visibility: hidden;
                transform: translateY(-10px);

                >svg {
                    position: absolute;
                    left: space(3);
                    top: space(3);
                }

                h4 {
                    color: #F25325;
                    font-size: 14px;
                    font-weight: 700;
                }

                p {
                    color: #323232;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                }

                ul {
                    list-style: disc;
                    padding-left: space(4);
                    color: #323232;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                }
            }
        }

        &:hover {
            :global {
                .popover {
                    visibility: visible;
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
    }

    &EmptyState {
        min-height: 65vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;

        svg {
            margin-bottom: space(4);
        }

        h4 {
            color: #3E3F44;
            font-size: 24px;
            font-weight: 700;
            line-height: 22px;
            margin-bottom: 8px;
        }

        p {
            color: #3E3F44;
            font-size: 14px;
            font-weight: 400;
            line-height: 25px;
        }
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}